import { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
/**
 *	Uvodni stranka
 */
export function Documentation() {
  // preklady
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("app.pageTitle") + " - " + t("dev.documentation.header");
  });

  return (
    <Container fluid>
      <h1>{t("dev.documentation.header")}</h1>

      <p>
        INBUS slouží jako vstupní brána pro přístup k API webových služeb poskytovaných univerzitou. Řeší autentizaci a
        autorizaci klientské aplikace (i uživatele pod kterým bude klientská aplikace vystupovat). Přístup je řešen
        pomocí OAuth2 protokolu. Slouží taky jako proxy, která požadavky zasílané na službu validuje a předává dál na
        konkretní implementaci služby.
      </p>

      <p>Jednotlivé služby se nacházejí na URL:</p>
      <pre>{"https://inbus.vsb.cz/service/{sluzba}/v{cislo_verze}/..."}</pre>
      <ul>
        <li>
          <strong>sluzba</strong> - název služby
        </li>
        <li>
          <strong>cislo_verze</strong> - 1,2,...
        </li>
      </ul>

      <p>
        URL a popis povolených služeb je možné nalézt na stránce <Link to="/dev/applications">seznamu aplikací</Link>{" "}
        pod tlačítkem <span className="badge bg-primary">{t("dev.applications.button.services")}</span>.
      </p>

      <h3>Autorizační protokol</h3>
      <ul>
        <li>
          <a href="#client">Client Credentials Flow</a>
        </li>
        <li>
          <a href="#code">Authorization Code Flow</a>
        </li>
      </ul>

      <h2 id="client">Autorizační protokol Client Credentials Flow</h2>

      <p>
        Tento druh přihlášení aplikace nevyžaduje součinnost uživatele a používá se pro služby které nepřistupují k
        osobním datům.
      </p>
      <p>Příklad pro telefonní seznam:</p>

      <h3>Phonebook - přístup služby přes INBUS</h3>

      <p>
        Pro přístup ke službě telefonního seznamu je potřeba získat autorizační token pomocí Client Credentials Flow.
        Pro získání tokenu k více službám najednou, je potřeba do parametru scope vypsat všechny služby oddělené
        mezerou.
      </p>

      <h4>Získání přístupového tokenu</h4>

      <p>
        <strong>POST request</strong>
      </p>
      <pre>https://inbus.vsb.cz/oauth/token</pre>
      <p>
        parametry ve formátu <code>application/x-www-form-urlencoded</code>:
      </p>
      <ul>
        <li>
          <code>grant_type=client_credentials</code>
        </li>
        <li>
          <code>client_id=</code>
          <em>/jednoznačný identifikátor klientské aplikace/</em>
        </li>
        <li>
          <code>client_secret=</code>
          <em>/přístupové heslo klientské aplikace/</em>
        </li>
        <li>
          <code>scope=</code>phonebook
        </li>
      </ul>

      <p>V odpovědi (JSON formát) se vrátí:</p>
      <ul>
        <li>
          <code>access_token</code> - přístupový token, který se použije pro volání API.
        </li>
        <li>
          <code>token_type</code> - typ tokenu
        </li>
        <li>
          <code>expires_in</code> - platnost tokenu ve vteřinách
        </li>
        <li>
          <code>scope</code> - autorizovaná oblast služeb
        </li>
      </ul>

      <h4>Volání služby</h4>

      <pre>https://inbus.vsb.cz/service/phonebook/v1/...</pre>
      <p>hlavička:</p>
      <pre>Authorization: Bearer access_token</pre>

      <p>
        Pokud dojde k vypršení access tokenu, vrátí se ze serveru odpověd <code>401 Unauthorized</code>. Nový token lze
        opětovně získat viz. výše.
      </p>

      <h2 id="code">Autorizační protokol Authorization Code Flow</h2>

      <p>
        Tento druh přihlášení aplikace vyžaduje součinnost uživatele a používá se pro služby které přistupují k osobním
        datům uživatele.
      </p>

      <p>Příklad pro testovací službu:</p>

      <h3>Test - přístup služby přes INBUS</h3>

      <p>
        Pro přístup k testovací službě je potřeba získat autorizační token pomocí Authorization Code Flow. Pro získání
        tokenu k více službám najednou, je potřeba do parametru scope vypsat všechny služby oddělené mezerou.
      </p>

      <h4>Získání přístupového tokenu</h4>

      <p>
        <strong>GET request v prohlížeči</strong>
      </p>

      <pre>https://inbus.vsb.cz/oauth/authorize</pre>
      <p>s parametry:</p>

      <ul>
        <li>
          <code>response_type=code</code>
        </li>
        <li>
          <code>client_id=</code>
          <em>/jednoznačný identifikátor klientské aplikace/</em>
        </li>
        <li>
          <code>state=</code>
          <em>/jedinečná hodnota kvůli CSRF/</em>
        </li>
        <li>
          <code>redirect_uri=</code>
          <em>/uri do aplikace (musí být definované přes uživatelské rozhraní INBUS)/</em>
        </li>
        <li>
          <code>scope=</code>test/user
        </li>
      </ul>

      <p>
        V případě chyby je uživatel přesmerován na <code>redirect_uri</code> s parametrem <code>error</code>, který
        specifikuje druh chyby.
      </p>
      <p>
        V případě úspěchu, je uživatel přesměrován na <code>redirect_uri</code> s těmito parametry:
      </p>
      <ul>
        <li>
          <code>code=</code>
          <em>/autorizační kód/</em>
        </li>
        <li>
          <code>state=</code>
          <em>/jedinečná hodnota kvůli CSRF (musí být stejná jako u prvního requestu)/</em>
        </li>
      </ul>

      <p>Pak lze získat access token pomocí</p>

      <p>
        <strong>POST request</strong>
      </p>
      <pre>https://inbus.vsb.cz/oauth/token</pre>
      <p>
        parametry ve formátu <code>application/x-www-form-urlencoded</code>:
      </p>

      <ul>
        <li>
          <code>code=</code>
          <em>/authorizační kód vrácený do aplikace v předchozím kroku/</em>
        </li>
        <li>
          <code>grant_type=authorization_code</code>
        </li>
        <li>
          <code>client_id=</code>
          <em>/jednoznačný identifikátor klientské aplikace/</em>
        </li>
        <li>
          <code>client_secret=</code>
          <em>/přístupové heslo klientské aplikace/</em>
        </li>
        <li>
          <code>redirect_uri=</code>
          <em>/uri do aplikace (musí být definované přes uživatelské rozhraní INBUS)/</em>
        </li>
      </ul>

      <p>V odpovědi (JSON formát) se vrátí:</p>

      <ul>
        <li>
          <code>access_token</code> - přístupový token, který se použije pro volání API.
        </li>
        <li>
          <code>token_type</code> - typ tokenu
        </li>
        <li>
          <code>expires_in</code> - platnost tokenu ve vteřinách
        </li>
        <li>
          <code>scope</code> - autorizovaná oblast služeb
        </li>
        <li>
          <code>refresh_token</code> - případně i refresh token pokud má uživatel povolený Refresh Token Grant
        </li>
      </ul>

      <h4>Volání služby</h4>

      <pre>https://inbus.vsb.cz/service/test/v1/user</pre>
      <p>hlavička:</p>
      <pre>Authorization: Bearer access_token</pre>

      <p>
        Služba vrátí identifikátor přihlášeného uživatele. Pokud dojde k vypršení access tokenu, vrátí se ze serveru
        odpověd <code>401 Unauthorized</code>. Nový token lze opětovně získat pomocí zaslaného refresh tokenu který má
        delší platnost.
      </p>

      <h3>Získání access tokenu pomocí refresh tokenu</h3>
      <p>Získat nový access token lze pomocí</p>

      <p>
        <strong>POST request</strong>
      </p>
      <pre>https://inbus.vsb.cz/oauth/token</pre>
      <p>
        parametry ve formátu <code>application/x-www-form-urlencoded</code>:
      </p>

      <ul>
        <li>
          <code>refresh_token=</code>
          <em>/refresh token vrácený do aplikace v předchozím kroku/</em>
        </li>
        <li>
          <code>grant_type=refresh_token</code>
        </li>
        <li>
          <code>client_id=</code>
          <em>/jednoznačný identifikátor klientské aplikace/</em>
        </li>
        <li>
          <code>client_secret=</code>
          <em>/přístupové heslo klientské aplikace/</em>
        </li>
      </ul>

      <p>V odpovědi (JSON formát) se vrátí:</p>

      <ul>
        <li>
          <code>access_token</code> - přístupový token, který se použije pro volání API.
        </li>
        <li>
          <code>expires_in</code> - platnost tokenu ve vteřinách
        </li>
        <li>
          <code>refresh_token</code> - případně i refresh token pokud má uživatel povolený Refresh Token Grant
        </li>
      </ul>

      <h3>Odvolání access a refresh tokenu</h3>

      <p>
        <strong>POST request</strong>
      </p>

      <pre>https://inbus.vsb.cz/oauth/revoke</pre>
      <p>
        parametry ve formátu <code>application/x-www-form-urlencoded</code>:
      </p>

      <ul>
        <li>
          <code>token=</code>
          <em>/token ke zneplatnění, bud access nebo refresh/</em>
        </li>
        <li>
          <code>client_id=</code>
          <em>/jednoznačný identifikátor klientské aplikace/</em>
        </li>
        <li>
          <code>client_secret=</code>
          <em>/přístupové heslo klientské aplikace/</em>
        </li>
      </ul>
    </Container>
  );
}
