import { inbusApi, UploadApplicationLogoApiArg } from "./adminBase";

/**
 * Rozsireni / Zmena vygenerovaneho API
 */
const injectedRtkApi = inbusApi.enhanceEndpoints({
  endpoints: {
    uploadApplicationLogo: {
      query(queryArg: UploadApplicationLogoApiArg) {
        let formData = new FormData();
        formData.append("logo", queryArg.logo);
        return {
          url: `/admin/applications/${queryArg.clientAppId}/logo`,
          method: "POST",
          body: formData,
        };
      },
    },
  },
});

export * from "./adminBase";
export const { useUploadApplicationLogoMutation } = injectedRtkApi;
