import { Form, InputGroup } from "react-bootstrap";
import { FieldValues, useController, UseControllerProps } from "react-hook-form";

interface Props<Type extends FieldValues> extends UseControllerProps<Type> {
  label?: string;
  className?: string;
  required?: boolean;
  children?: JSX.Element;
  placeholder?: string;
  rows?: number;
  type?: string;
  readOnly?: boolean;
}

/**
 *	Vstupni formularove policko
 */
export function Input<Type extends FieldValues>({ control, name, className = "mb-3", ...props }: Props<Type>) {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  if (!props.children) {
    return (
      <Form.Group className={className + (props.required ? " required" : "")} controlId={field.name}>
        {props.label && <Form.Label>{props.label}</Form.Label>}
        {!props.rows && (
          <Form.Control
            {...field}
            isInvalid={error != null}
            placeholder={props.placeholder}
            type={props.type}
            readOnly={props.readOnly}
          />
        )}
        {props.rows && (
          <Form.Control
            as="textarea"
            {...field}
            isInvalid={error != null}
            rows={props.rows}
            placeholder={props.placeholder}
            type={props.type}
            readOnly={props.readOnly}
          />
        )}
        <Form.Control.Feedback type="invalid">{error?.message}</Form.Control.Feedback>
      </Form.Group>
    );
  } else {
    return (
      <Form.Group className={className + (props.required ? " required" : "")} controlId={field.name}>
        {props.label && <Form.Label>{props.label}</Form.Label>}
        <InputGroup hasValidation={true}>
          <Form.Control
            {...field}
            isInvalid={error != null}
            placeholder={props.placeholder}
            type={props.type}
            readOnly={props.readOnly}
          />
          {props.children}
          <Form.Control.Feedback type="invalid">{error?.message}</Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
    );
  }
}
