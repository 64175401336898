import { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  useFindServiceScopesQuery,
  useRemoveServiceScopeMutation,
  Scope,
  Service,
} from "../../../api/admin";
import { useAppDispatch } from "../../../app/hooks";
import { showInfo } from "../../message";
import { PlusSquare, PencilSquare, Trash, Eye, PersonCheckFill, PersonFill } from "react-bootstrap-icons";
import ScopeModal from "./ScopeModal";
import ClientAppsModal from "./ClientAppsModal";
import { tl } from "../../../i18n";

interface Props {
  service: Service;
}

interface AppModalState {
  show: boolean;
  scopeId?: number;
}

/**
 *	Opravneni aplikace
 */
export default function Scopes({ service }: Props) {
  const dispatch = useAppDispatch();
  // preklady
  const { t } = useTranslation();

  // Editacni okno
  const [modalShow, setModalShow] = useState(false);

  // Okno aplikaci
  const [appModalShow, setAppModalShow] = useState<AppModalState>({ show: false });

  // Editovany rozsah
  const [scope, setScope] = useState({
    title: "",
    readonly: false,
    requireUserIdentity: false,
    respondsToUserIdentity: false,
    description: "",
    apidoc: "",
    restrictionRegex: "",
  } as Scope);

  let { data: scopes } = useFindServiceScopesQuery({ serviceId: service.id! });

  // akce
  const [removeServiceScope, removeServiceScopeResult] = useRemoveServiceScopeMutation();

  const handleCreate = () => {
    setScope({
      title: "",
      readonly: false,
      requireUserIdentity: false,
      respondsToUserIdentity: false,
      description: "",
      apidoc: "",
      restrictionRegex: "",
    });
    setModalShow(true);
  };

  const handleEdit = (scope: Scope) => {
    setScope(scope);
    setModalShow(true);
  };

  const handleRemove = (scopeId: number | undefined) => {
    if (window.confirm(t("confirm.remove")) && service?.id && scopeId) {
      removeServiceScope({ serviceId: service.id, scopeId: scopeId });
    }
  };

  const handleShowApps = (scope: Scope) => {
    setAppModalShow({ show: true, scopeId: scope.id! });
  };

  // akce po ulozeni dat
  useEffect(() => {
    if (removeServiceScopeResult.isSuccess) {
      dispatch(showInfo(t("info.removed")));
    }
  }, [dispatch, t, removeServiceScopeResult]);

  return (
    <div>
      <ScopeModal show={modalShow} onHide={() => setModalShow(false)} serviceId={service.id!} scope={scope} />
      {appModalShow.scopeId && (
        <ClientAppsModal
          show={appModalShow.show}
          onHide={() => setAppModalShow({ show: false })}
          serviceId={service.id!}
          scopeId={appModalShow.scopeId}
        />
      )}

      <Table hover responsive="md">
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th className="text-end">
              <Button className="py-0" variant="link" onClick={() => handleCreate()}>
                <PlusSquare />
              </Button>
            </th>
          </tr>
        </thead>
        <tbody>
          {scopes &&
            scopes.map((s, index) => (
              <tr key={index}>
                <td>
                  <span
                    role="button"
                    className="fw-bold pe-auto"
                    onClick={() => handleShowApps(s)}
                    title={t("admin.service.title.scope")}
                  >
                    {s.title}
                  </span>
                  {s.readonly && <Eye className="mx-1 text-muted" title={t("title.scopeReadonly")} />}
                  {s.respondsToUserIdentity && (
                    <PersonFill className="mx-1 text-muted" title={t("title.scopeRespondsToUserIdentity")} />
                  )}
                  {s.requireUserIdentity && (
                    <PersonCheckFill className="mx-1 text-warning" title={t("title.scopeRequireUserIdentity")} />
                  )}
                  <small>
                    <p className="fst-italic">{tl(s.description,s.descriptionEN)}</p>
                  </small>
                </td>
                <td>
                  <small className="fw-light text-muted">
                    <span className="fw-bold" title={t("admin.service.label.apidoc")}>
                      {t("admin.service.column.apidoc")}:
                    </span>{" "}
                    <span>{s.apidoc}</span>
                    <br />
                    <span className="fw-bold" title={t("admin.service.label.restrictionRegex")}>
                      {t("admin.service.column.restrictionRegex")}:
                    </span>{" "}
                    <span>{s.restrictionRegex}</span>
                  </small>
                </td>
                <td className="text-end">
                  <Button className="py-0" variant="link" onClick={() => handleEdit(s)}>
                    <PencilSquare />
                  </Button>
                  <Button className="py-0 text-danger" variant="link" onClick={() => handleRemove(s.id)}>
                    <Trash />
                  </Button>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </div>
  );
}
