import { useEffect } from "react";
import { Container, Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { useGetUserProfileQuery } from "../../../api/auth";
import { useFindMyApplicationQuery, useSaveMyApplicationMutation, ClientAppBase } from "../../../api/dev";
import { useAppDispatch } from "../../../app/hooks";
import { showInfo } from "../../message";
import { stripEmpty } from "../../../utils/form";
import { TrashFill } from "react-bootstrap-icons";
import { GrantType } from "../../../utils/consts";
import * as yup from "yup";
import UI from "../../ui";

// pro pouziti ve formulari
type ClientAppUForm = ClientAppBase & { formRedirectUris?: { uri: string }[] };

/**
 *	Uprava aplikace
 */
export function Application() {
  const dispatch = useAppDispatch();
  // preklady
  const { t } = useTranslation();

  // schema validace
  const schema = yup
    .object({
      title: yup.string(), // potreba pro validaci na serveru (stripUnknown: true)
      version: yup.number(), // potreba pro validaci na serveru (stripUnknown: true)
      description: yup.string().trim().max(4095).required(),
      descriptionEN: yup.string().trim().max(4095),
      web: yup.string().url().optional().transform(stripEmpty),
      redirectUris: yup.array().of(yup.string().required()),

      formRedirectUris: yup.array().of(
        yup.object({
          uri: yup
            .string()
            .test("url", t("field.string.url"), (value) => {
              // url() neumi validovat localhost
              if (!value) return true;
              try {
                const valid = new URL(value!);
                return Boolean(valid);
              } catch {
                return false;
              }
            })
            .required(),
        })
      ), // pouze pro formular
    })
    .transform((o: ClientAppUForm) => {
      return { ...o, redirectUris: o.formRedirectUris!.map((e) => e.uri) };
    })
    .required();

  const navigate = useNavigate();

  // validace formulare
  const { control, handleSubmit, reset } = useForm<ClientAppUForm>({
    defaultValues: {
      description: "",
      web: "",
      redirectUris: [],
      formRedirectUris: [],
    },
    resolver: yupResolver(schema, { stripUnknown: true }),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "formRedirectUris",
  });

  let { clientAppId } = useParams();

  let id = clientAppId ? parseInt(clientAppId) : 0;

  // nacteni profilu
  const { data: userProfile } = useGetUserProfileQuery();

  const { data: application } = useFindMyApplicationQuery({ clientAppId: id }, { skip: id === 0 });
  // akce ulozeni profilu
  const [saveApplication, saveApplicationResult] = useSaveMyApplicationMutation();

  // titulek stranky
  useEffect(() => {
    document.title = t("app.pageTitle") + " - " + t("dev.application.header");
  });

  // nacteni hodnot do formulare
  useEffect(() => {
    if (application) {
      // konverze na parametr formRedirectUris, protoze react-hook-form neumi zpracovat flat pole
      reset({
        formRedirectUris: application.redirectUris.map((e) => ({
          uri: e,
        })),
        ...application,
      });
    }
  }, [reset, application]);

  // akce po ulozeni dat
  useEffect(() => {
    if (saveApplicationResult.isSuccess) {
      dispatch(showInfo(t("info.saved")));
      navigate("/dev/applications");
    }
  }, [dispatch, t, saveApplicationResult, navigate]);

  // ulozeni formulare
  const onSubmit = (data: ClientAppUForm) => {
    delete data.formRedirectUris;
    saveApplication({ clientAppId: id, clientAppBase: data });
  };

  let authorizationCodeGrant = false;
  application?.grantTypes.forEach((g) => {
    if (g.id === GrantType.AUTHORIZATION_CODE_GRANT) authorizationCodeGrant = true;
  });

  // zjisteni zda uzivatel ma prava admin aplikace
  let admin = false;
  application?.developers.forEach((d) => {
    if (d.login === userProfile?.login && d.admin) admin = true;
  });

  return (
    <Container fluid>
      <h1>
        {application?.title}
        <span className="header-type">{t("dev.application.header")}</span>
      </h1>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <UI.Input
          control={control}
          name="description"
          rows={3}
          label={t("label.description")}
          required={true}
          readOnly={!admin}
        />
        <UI.Input
          control={control}
          name="descriptionEN"
          rows={3}
          label={t("label.description") + " (EN)"}
          required={false}
          readOnly={!admin}
        />
        <UI.Input control={control} name="web" label={t("label.web")} readOnly={!admin} />
        <Form.Group className="mb-3 required" controlId="formClientId">
          <Form.Label>{t("label.clientId")}</Form.Label>
          {application && (
            <Form.Control
              plaintext
              readOnly
              value={application?.clientId ? application.clientId : t("dev.application.text.generatedAfterSave")}
            />
          )}
        </Form.Group>
        <Form.Group className="mb-3 required" controlId="formClientSecret">
          <Form.Label>{t("label.clientSecret")}</Form.Label>
          {application && (
            <Form.Control
              plaintext
              readOnly
              value={application.clientSecret ? application.clientSecret : t("dev.application.text.generatedAfterSave")}
            />
          )}
        </Form.Group>
        {authorizationCodeGrant && (
          <Form.Group className="mb-3" controlId="formRedirectUrls">
            <Form.Label>{t("label.redirectUris")}</Form.Label>
            <div>
              {fields.map((field, index) => {
                return (
                  <div key={field.id}>
                    {admin && (
                      <UI.Input key={field.id} control={control} name={`formRedirectUris.${index}.uri`}>
                        <Button variant="outline-danger" onClick={() => remove(index)}>
                          <TrashFill />
                        </Button>
                      </UI.Input>
                    )}
                    {!admin && (
                      <Controller
                        name={`formRedirectUris.${index}.uri`}
                        control={control}
                        render={({ field }) => <Form.Control className="mb-3" {...field} readOnly />}
                      />
                    )}
                  </div>
                );
              })}
            </div>
            {admin && (
              <Button variant="secondary" onClick={() => append({ uri: "" })}>
                {t("button.add")}
              </Button>
            )}
          </Form.Group>
        )}
        {admin && <UI.ActionButton label={t("button.save")} isLoading={saveApplicationResult.isLoading} />}
      </Form>
    </Container>
  );
}
