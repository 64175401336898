import { Navbar, Stack, Nav } from "react-bootstrap";
import { useHref, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGetUserProfileQuery, useLogoutMutation } from "../../api/auth";
import { Menu as DevMenu } from "../dev";
import { Menu as AdminMenu } from "../admin";
import logo from "../../images/logo192.png";

/**
 *	Hlavicka
 */
export function Header() {
  // preklady
  const { t } = useTranslation();

  const location = useLocation();

  // navigace
  let url = "/";
  if (location.pathname.startsWith("/user")) {
    url = "/user";
  } else if (location.pathname.startsWith("/dev")) {
    url = "/dev";
  } else if (location.pathname.startsWith("/admin")) {
    url = "/admin";
  }

  const brandHref = useHref(url);
  const homeHref = useHref("/");
  const logoutSSOHref = useHref("/cas-logout");

  // nacteni profilu
  const { data: userProfile } = useGetUserProfileQuery();

  // rest akce
  const [logout] = useLogoutMutation();

  let roleDeveloper = false;
  let roleAdmin = false;
  if (userProfile) {
    roleDeveloper = userProfile.roles.includes("ROLE_DEVELOPER");
    roleAdmin = userProfile.roles.includes("ROLE_ADMIN");
  }

  return (
    <Navbar bg="light" expand="lg" className="px-3 mb-3">
      <Navbar.Brand href={brandHref} className="py-2">
        <img className="me-1 mb-1" height={26} src={logo} alt="Logo" />
        <span className="d-none d-md-inline">{t("header.text.main")}</span>
        <span className="d-inline d-md-none">{t("header.text.mainShort")}</span>
      </Navbar.Brand>
      <Navbar.Toggle />
      <Navbar.Collapse className="flex-lg-column">
        <Nav id="menu-profile" className="ms-auto">
          {!userProfile && <Navbar.Text>{t("header.text.unauthenticated")}</Navbar.Text>}
          {userProfile && (
            <Navbar.Text className="py-0">
              <Stack direction="horizontal" gap={2}>
                <div className="text-end ms-auto">
                  <div>
                    <small>{userProfile?.organisationTitle}</small>
                  </div>
                  <span className="fw-bold" title={userProfile?.organisationTitle}>
                    {userProfile.login}
                  </span>
                </div>
                {roleDeveloper && (
                  <a href={homeHref} className="ms-2" onClick={() => logout()}>
                    {t("header.link.logout")}
                  </a>
                )}
                {!roleDeveloper && (
                  <span>
                    <a href={homeHref} className="ms-2" onClick={() => logout()}>
                      {t("header.link.logout")}
                    </a>
                    <span className="ms-1">
                      (<a href={logoutSSOHref}>SSO</a>)
                    </span>
                  </span>
                )}
              </Stack>
            </Navbar.Text>
          )}
        </Nav>
        {roleDeveloper && url === "/dev" && <DevMenu />}
        {roleAdmin && url === "/admin" && <AdminMenu />}
      </Navbar.Collapse>
    </Navbar>
  );
}
