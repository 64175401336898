import { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  useFindOrganisationsQuery,
  useFindServiceOrganisationServicesQuery,
  useSaveServiceOrganisationServicesMutation,
  OrganisationServiceBase,
  Service,
} from "../../../api/admin";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch } from "../../../app/hooks";
import { showInfo } from "../../message";
import UI from "../../ui";
import * as yup from "yup";
import { stripEmpty } from "../../../utils/form";

interface Props {
  service: Service;
}

// predefinovani url jako nepovinne
interface OrganisationServiceUForm extends Omit<OrganisationServiceBase, "url"> {
  url?: string;
}

// mnozina organizaci
interface OrganisationsForm {
  organisations: OrganisationServiceUForm[];
}

/**
 *	Implementace api
 */
export default function Implementations({ service }: Props) {
  const dispatch = useAppDispatch();
  // preklady
  const { t } = useTranslation();

  // schema validace
  const schema: yup.SchemaOf<OrganisationsForm> = yup.object({
    organisations: yup.array().of(
      yup.object({
        id: yup.number(),
        version: yup.number(), // potreba pro validaci na serveru (stripUnknown: true)
        url: yup
          .string()
          .test("url", t("field.string.url"), (value) => {
            // url() neumi validovat localhost
            if (!value) return true;
            try {
              const valid = new URL(value!);
              return Boolean(valid);
            } catch {
              return false;
            }
          })
          .max(255)
          .optional()
          .transform(stripEmpty),
        organisationId: yup.number().required(),
      })
    ),
  });

  // validace formulare
  const { control, handleSubmit, reset } = useForm<OrganisationsForm>({
    defaultValues: { organisations: [] },
    resolver: yupResolver(schema, { stripUnknown: true }),
  });

  let { data: organisations } = useFindOrganisationsQuery();

  let { data: serviceOrganisations } = useFindServiceOrganisationServicesQuery({ serviceId: service.id! });

  // akce
  const [saveServiceOrganisationServices, saveServiceOrganisationServicesResult] =
    useSaveServiceOrganisationServicesMutation();

  // nacteni hodnot do formulare
  useEffect(() => {
    let data = organisations?.map((o) => {
      let organisationService: OrganisationServiceUForm = { organisationId: o.id!, url: "" };
      serviceOrganisations?.forEach((so) => {
        if (so.organisationId === o.id) {
          organisationService.id = so.id;
          organisationService.url = so.url;
          organisationService.version = so.version;
        }
      });
      return organisationService;
    });
    if (!data) data = [];
    reset({ organisations: data });
  }, [reset, organisations, serviceOrganisations]);

  // ulozeni formulare
  const onSubmit = (data: OrganisationsForm) => {
    data.organisations = data.organisations.filter((o) => o.url);

    saveServiceOrganisationServices({ serviceId: service.id!, body: data.organisations as OrganisationServiceBase[] });
  };

  // akce po ulozeni dat
  useEffect(() => {
    if (saveServiceOrganisationServicesResult.isSuccess) {
      dispatch(showInfo(t("info.saved")));
    }
  }, [dispatch, t, saveServiceOrganisationServicesResult]);

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      {organisations?.map((field, index) => (
        <UI.Input key={index} control={control} name={`organisations.${index}.url`} label={field.title} />
      ))}
      <UI.ActionButton isLoading={saveServiceOrganisationServicesResult.isLoading} label={t("button.save")} />
    </Form>
  );
}
