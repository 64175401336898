import { useEffect, Suspense, lazy } from "react";
import "./App.scss";
import { Container, Row, Col } from "react-bootstrap";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Header } from "./features/header";
import { Welcome } from "./features/welcome";
import { Login, AccessDenied } from "./features/auth";
import { AlertMessage } from "./features/message";
// import UserRoutes from "./features/user/routes";
// import DevRoutes from "./features/dev/routes";
// import AdminRoutes from "./features/admin/routes";
import { useGetUserProfileQuery } from "./api/auth";

const UserRoutes = lazy(() => import("./features/user/routes"));
const DevRoutes = lazy(() => import("./features/dev/routes"));
const AdminRoutes = lazy(() => import("./features/admin/routes"));

function App() {
  const location = useLocation();
  const navigate = useNavigate();

  const { data: userProfile, isFetching } = useGetUserProfileQuery();

  let roleDeveloper = false;
  let roleUser = false;
  let roleAdmin = false;
  if (userProfile) {
    roleUser = userProfile.roles.includes("ROLE_USER");
    roleDeveloper = userProfile.roles.includes("ROLE_DEVELOPER");
    roleAdmin = userProfile.roles.includes("ROLE_ADMIN");
  }

  useEffect(() => {
    if (!isFetching) {
      if (!userProfile) {
        // neni autentizovan
        if (location.pathname.startsWith("/user")) {
          window.location.href = "/user/login";
        } else if (location.pathname.startsWith("/dev") && !location.pathname.startsWith("/dev/login")) {
          navigate("/dev/login?rurl=" + location.pathname);
        } else if (location.pathname.startsWith("/admin")) {
          window.location.href = "/admin/login";
        }
      } else {
        // je autentizova - kontrola opravneni
        if (!roleUser && location.pathname.startsWith("/user")) {
        } else if (
          !roleDeveloper &&
          location.pathname.startsWith("/dev") &&
          !location.pathname.startsWith("/dev/login")
        ) {
        } else if (!roleAdmin && location.pathname.startsWith("/admin")) {
        }
      }
    }
  });

  return (
    <div className="App">
      <Header />
      <AlertMessage />
      <Container fluid>
        <Row className="justify-content-md-center">
          <Col xs="12" md="11" lg="10" xl="9">
            <Suspense fallback={<div>Loading...</div>}>
              <Routes>
                <Route path="/" element={<Welcome />} />
                <Route path="/dev/login" element={<Login />} />

                {roleUser && <Route path="/user/*" element={<UserRoutes />} />}
                {userProfile && !roleUser && <Route path="/user" element={<AccessDenied />} />}

                {roleDeveloper && <Route path="/dev/*" element={<DevRoutes />} />}
                {userProfile && !roleDeveloper && <Route path="/dev" element={<AccessDenied />} />}

                {roleAdmin && <Route path="/admin/*" element={<AdminRoutes />} />}
                {userProfile && !roleAdmin && <Route path="/admin" element={<AccessDenied />} />}
              </Routes>
            </Suspense>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default App;
