import { useEffect } from "react";
import { Container, Table, Form, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { PencilSquare, PlusSquare } from "react-bootstrap-icons";
import { useFindDeveloperAccountsQuery } from "../../../api/admin";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";

// pro pouziti ve formulari
type CriteriaForm = {
  search?: string;
};

// schema validace
const schema: yup.SchemaOf<CriteriaForm> = yup.object({
  search: yup.string().optional(),
});

/**
 *	Aplikace
 */
export function Developers() {
  // preklady
  const { t } = useTranslation();

  // validace formulare
  const {
    control,
    watch,
    formState: { errors, isValid },
  } = useForm<CriteriaForm>({
    mode: "onChange",
    defaultValues: {
      search: "",
    },
    resolver: yupResolver(schema, { stripUnknown: true }),
  });

  const search = isValid ? watch("search") : undefined;

  const { data: developers } = useFindDeveloperAccountsQuery({ search: search });

  useEffect(() => {
    document.title = t("app.pageTitle") + " - " + t("admin.developers.header");
  });

  return (
    <Container fluid>
      <h1>{t("admin.developers.header")}</h1>
      <Row sm={2} md={3}>
        <Col>
          <Form noValidate>
            <Form.Group className="mb-3" controlId="formSearch">
              <Controller
                name="search"
                control={control}
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    isInvalid={errors.search != null}
                    placeholder={t("admin.developers.placeholder.search")}
                  />
                )}
              />
              <Form.Control.Feedback type="invalid">{errors.search?.message}</Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Col>
      </Row>
      <Table hover responsive="md">
        <thead>
          <tr>
            <th>{t("admin.developers.column.firstName")}</th>
            <th>{t("admin.developers.column.lastName")}</th>
            <th>{t("admin.developers.column.login")}</th>
            <th>{t("admin.developers.column.organisation")}</th>
            <th>{t("admin.developers.column.phone")}</th>
            <th className="text-end">
              <Link to={"/admin/developers/create"}>
                <PlusSquare />
              </Link>
            </th>
          </tr>
        </thead>
        <tbody>
          {developers?.map((d, index) => (
            <tr key={index}>
              <td>{d.firstName}</td>
              <td>{d.lastName}</td>
              <td>{d.login}</td>
              <td>{d.organisation}</td>
              <td>{d.phone}</td>
              <td className="text-end">
                <Link to={"/admin/developers/" + d.id}>
                  <PencilSquare />
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}
