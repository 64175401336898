import { pickBy, mapValues } from "lodash";

// odstrani vsechny prazdne textove hodnoty z objektu
export function strip<Type>(o: Type): Type {
  return pickBy(o as object, (value: string | any) => typeof value !== "string" || value.trim().length > 0) as Type;
}

// pripravi objekt k odeslani na server (odstrani vsechny prazdne textove hodnoty z objektu a provede trim na textove polozky)
export function cultivate<Type>(o: Type): Type {
  let trimmed = mapValues(o as object, (value: string | any) => (typeof value === "string" ? value.trim() : value));
  return pickBy(trimmed as object, (value: string | any) => typeof value !== "string" || value.length > 0) as Type;
}

/*
	Pokud je prazda hodnota vrati undefined
	*/
export function stripEmpty(value: string): string | undefined {
  return value === "" ? undefined : value;
}
