import { inbusSplitApi as api } from "./inbusApi";
export const addTagTypes = ["DeveloperAccount", "MyApplication"] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      changePassword: build.mutation<ChangePasswordApiResponse, ChangePasswordApiArg>({
        query: (queryArg) => ({ url: `/dev/account/password`, method: "PUT", body: queryArg.changePassword }),
        invalidatesTags: ["DeveloperAccount"],
      }),
      findMyAccount: build.query<FindMyAccountApiResponse, FindMyAccountApiArg>({
        query: () => ({ url: `/dev/account` }),
        providesTags: ["DeveloperAccount"],
      }),
      saveMyAccount: build.mutation<SaveMyAccountApiResponse, SaveMyAccountApiArg>({
        query: (queryArg) => ({ url: `/dev/account`, method: "PUT", body: queryArg.developerAccount }),
        invalidatesTags: ["DeveloperAccount"],
      }),
      findMyApplicationDevelopers: build.query<
        FindMyApplicationDevelopersApiResponse,
        FindMyApplicationDevelopersApiArg
      >({
        query: (queryArg) => ({ url: `/dev/applications/${queryArg.clientAppId}/developers` }),
        providesTags: ["MyApplication"],
      }),
      addMyApplicationDeveloper: build.mutation<AddMyApplicationDeveloperApiResponse, AddMyApplicationDeveloperApiArg>({
        query: (queryArg) => ({
          url: `/dev/applications/${queryArg.clientAppId}/developers`,
          method: "POST",
          body: queryArg.developerBase,
        }),
        invalidatesTags: ["MyApplication"],
      }),
      findMyApplication: build.query<FindMyApplicationApiResponse, FindMyApplicationApiArg>({
        query: (queryArg) => ({ url: `/dev/applications/${queryArg.clientAppId}` }),
        providesTags: ["MyApplication"],
      }),
      saveMyApplication: build.mutation<SaveMyApplicationApiResponse, SaveMyApplicationApiArg>({
        query: (queryArg) => ({
          url: `/dev/applications/${queryArg.clientAppId}`,
          method: "PUT",
          body: queryArg.clientAppBase,
        }),
        invalidatesTags: ["MyApplication"],
      }),
      findMyApplicationGrantTypes: build.query<
        FindMyApplicationGrantTypesApiResponse,
        FindMyApplicationGrantTypesApiArg
      >({
        query: (queryArg) => ({ url: `/dev/applications/${queryArg.clientAppId}/grantTypes` }),
        providesTags: ["MyApplication"],
      }),
      findMyApplicationLogo: build.query<FindMyApplicationLogoApiResponse, FindMyApplicationLogoApiArg>({
        query: (queryArg) => ({ url: `/dev/applications/${queryArg.clientAppId}/logo` }),
        providesTags: ["MyApplication"],
      }),
      uploadMyApplicationLogo: build.mutation<UploadMyApplicationLogoApiResponse, UploadMyApplicationLogoApiArg>({
        query: (queryArg) => ({
          url: `/dev/applications/${queryArg.clientAppId}/logo`,
          method: "POST",
          params: { logo: queryArg.logo },
        }),
        invalidatesTags: ["MyApplication"],
      }),
      findMyApplicationScope: build.query<FindMyApplicationScopeApiResponse, FindMyApplicationScopeApiArg>({
        query: (queryArg) => ({ url: `/dev/applications/${queryArg.clientAppId}/scope/${queryArg.scopeId}` }),
        providesTags: ["MyApplication"],
      }),
      findMyApplicationScopes: build.query<FindMyApplicationScopesApiResponse, FindMyApplicationScopesApiArg>({
        query: (queryArg) => ({ url: `/dev/applications/${queryArg.clientAppId}/scopes` }),
        providesTags: ["MyApplication"],
      }),
      findMyApplicationServices: build.query<FindMyApplicationServicesApiResponse, FindMyApplicationServicesApiArg>({
        query: (queryArg) => ({ url: `/dev/applications/${queryArg.clientAppId}/services` }),
        providesTags: ["MyApplication"],
      }),
      findMyApplications: build.query<FindMyApplicationsApiResponse, FindMyApplicationsApiArg>({
        query: () => ({ url: `/dev/applications` }),
        providesTags: ["MyApplication"],
      }),
      saveMyApplicationDeveloper: build.mutation<
        SaveMyApplicationDeveloperApiResponse,
        SaveMyApplicationDeveloperApiArg
      >({
        query: (queryArg) => ({
          url: `/dev/applications/${queryArg.clientAppId}/developers/${queryArg.developerId}`,
          method: "PUT",
          body: queryArg.developerBase,
        }),
        invalidatesTags: ["MyApplication"],
      }),
      removeMyApplicationDeveloper: build.mutation<
        RemoveMyApplicationDeveloperApiResponse,
        RemoveMyApplicationDeveloperApiArg
      >({
        query: (queryArg) => ({
          url: `/dev/applications/${queryArg.clientAppId}/developers/${queryArg.developerId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["MyApplication"],
      }),
      openapi: build.query<OpenapiApiResponse, OpenapiApiArg>({
        query: (queryArg) => ({ url: `/dev/openapi`, params: { scope: queryArg.scope } }),
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as inbusApi };
export type ChangePasswordApiResponse = unknown;
export type ChangePasswordApiArg = {
  changePassword: ChangePassword;
};
export type FindMyAccountApiResponse = /** status 200 undefined */ DeveloperAccount;
export type FindMyAccountApiArg = void;
export type SaveMyAccountApiResponse = /** status 200 undefined */ DeveloperAccount;
export type SaveMyAccountApiArg = {
  developerAccount: DeveloperAccount;
};
export type FindMyApplicationDevelopersApiResponse = /** status 200 undefined */ Developer[];
export type FindMyApplicationDevelopersApiArg = {
  clientAppId: number;
};
export type AddMyApplicationDeveloperApiResponse = unknown;
export type AddMyApplicationDeveloperApiArg = {
  clientAppId: number;
  developerBase: DeveloperBase;
};
export type FindMyApplicationApiResponse = /** status 200 undefined */ ClientAppDev;
export type FindMyApplicationApiArg = {
  clientAppId: number;
};
export type SaveMyApplicationApiResponse = /** status 200 undefined */ ClientAppDev;
export type SaveMyApplicationApiArg = {
  clientAppId: number;
  clientAppBase: ClientAppBase;
};
export type FindMyApplicationGrantTypesApiResponse = /** status 200 undefined */ GrantType[];
export type FindMyApplicationGrantTypesApiArg = {
  clientAppId: number;
};
export type FindMyApplicationLogoApiResponse = unknown;
export type FindMyApplicationLogoApiArg = {
  clientAppId: number;
};
export type UploadMyApplicationLogoApiResponse = unknown;
export type UploadMyApplicationLogoApiArg = {
  logo: any;
  clientAppId: number;
};
export type FindMyApplicationScopeApiResponse = /** status 200 undefined */ ApplicationServiceScope;
export type FindMyApplicationScopeApiArg = {
  clientAppId: number;
  scopeId: number;
};
export type FindMyApplicationScopesApiResponse = /** status 200 undefined */ Scope[];
export type FindMyApplicationScopesApiArg = {
  clientAppId: number;
};
export type FindMyApplicationServicesApiResponse = /** status 200 undefined */ ApplicationService[];
export type FindMyApplicationServicesApiArg = {
  clientAppId: number;
};
export type FindMyApplicationsApiResponse = /** status 200 undefined */ ClientApp[];
export type FindMyApplicationsApiArg = void;
export type SaveMyApplicationDeveloperApiResponse = unknown;
export type SaveMyApplicationDeveloperApiArg = {
  clientAppId: number;
  developerId: number;
  developerBase: DeveloperBase;
};
export type RemoveMyApplicationDeveloperApiResponse = unknown;
export type RemoveMyApplicationDeveloperApiArg = {
  clientAppId: number;
  developerId: number;
};
export type OpenapiApiResponse = unknown;
export type OpenapiApiArg = {
  scope?: string;
};
export type Error = {
  code: string;
  message?: string;
};
export type ChangePassword = {
  oldPassword: string;
  newPassword: string;
};
export type DeveloperAccount = {
  version?: number;
  id?: number;
  login: string;
  firstName: string;
  lastName: string;
  organisation?: string;
  phone?: string;
};
export type Developer = {
  id?: number;
  version?: number;
  admin?: boolean;
  login: string;
  firstName: string;
  lastName: string;
  organisation?: string;
};
export type DeveloperBase = {
  id?: number;
  version?: number;
  admin?: boolean;
  developerAccountEmail?: string;
};
export type GrantType = {
  id?: number;
  key: string;
  title: string;
  tokenExpiration?: number;
};
export type ClientAppDev = {
  id?: number;
  version?: number;
  title: string;
  description: string;
  descriptionEN?: string;
  logo?: string[];
  web?: string;
  clientId?: string;
  clientSecret?: string;
  grantTypes: GrantType[];
  redirectUris: string[];
  developers: Developer[];
};
export type ClientAppBase = {
  id?: number;
  version?: number;
  title: string;
  description: string;
  descriptionEN?: string;
  logo?: string[];
  web?: string;
  clientId?: string;
  clientSecret?: string;
  redirectUris: string[];
  scopeIds?: number[];
  grantTypeIds?: number[];
};
export type ApplicationServiceScope = {
  id?: number;
  title: string;
  readonly: boolean;
  requireUserIdentity: boolean;
  respondsToUserIdentity: boolean;
  description: string;
};
export type Scope = {
  id?: number;
  version?: number;
  title: string;
  readonly: boolean;
  apidoc?: string;
  requireUserIdentity: boolean;
  respondsToUserIdentity: boolean;
  description: string;
  descriptionEN?: string;
  restrictionRegex?: string;
};
export type ApplicationService = {
  id?: number;
  title: string;
  description: string;
  contextPath: string;
  apiVersion: number;
  scopes: ApplicationServiceScope[];
};
export type ClientApp = {
  id?: number;
  title: string;
  description: string;
  logo?: string[];
  web?: string;
  grantTypes: GrantType[];
  developers: Developer[];
};
export const {
  useChangePasswordMutation,
  useFindMyAccountQuery,
  useSaveMyAccountMutation,
  useFindMyApplicationDevelopersQuery,
  useAddMyApplicationDeveloperMutation,
  useFindMyApplicationQuery,
  useSaveMyApplicationMutation,
  useFindMyApplicationGrantTypesQuery,
  useFindMyApplicationLogoQuery,
  useUploadMyApplicationLogoMutation,
  useFindMyApplicationScopeQuery,
  useFindMyApplicationScopesQuery,
  useFindMyApplicationServicesQuery,
  useFindMyApplicationsQuery,
  useSaveMyApplicationDeveloperMutation,
  useRemoveMyApplicationDeveloperMutation,
  useOpenapiQuery,
} = injectedRtkApi;
