import { useEffect } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

/**
 *	Vitejte
 */
export function Welcome() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = t("app.pageTitle") + " - " + t("welcome.header");
  });

  return (
    <div>
      <h1>{t("welcome.header")}</h1>
      <Row>
        <Col md="7">
          <Card bg="light" className="my-2">
            <Card.Body>
              <Card.Text>{t("welcome.text.user")}</Card.Text>
              <Button variant="primary" onClick={() => navigate("user")}>
                {t("welcome.button.user")}
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col md="5">
          <Card bg="light" className="my-2">
            <Card.Body>
              <Card.Text>{t("welcome.text.developer")}</Card.Text>
              <Button variant="secondary" size="sm" onClick={() => navigate("dev")}>
                {t("welcome.button.developer")}
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
