import { useEffect, useRef } from "react";
import { Container, Row, Col, Card, Image, Dropdown, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ThreeDotsVertical } from "react-bootstrap-icons";
import { useFindMyApplicationsQuery, useUploadMyApplicationLogoMutation } from "../../../api/dev";
import { useAppDispatch } from "../../../app/hooks";
import { showError, showInfo } from "../../message";

/**
 *	Aplikace
 */
export function Applications() {
  const dispatch = useAppDispatch();
  // preklady
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { data: applications } = useFindMyApplicationsQuery();

  // akce nahrani loga
  const [uploadLogo, uploadLogoResult] = useUploadMyApplicationLogoMutation();

  const inputRefs = useRef<HTMLInputElement[]>([]);

  useEffect(() => {
    document.title = t("app.pageTitle") + " - " + t("dev.applications.header");
  });

  const handleUpload = (index: number) => {
    inputRefs.current[index]?.click();
  };

  const handleUploadFile = (index: number, clientAppId: number | undefined) => {
    let files = inputRefs.current[index]?.files;
    if (files && clientAppId) {
      let file = files[0];
      if ("image/png" !== file.type) {
        dispatch(showError(t("error.uploadPngRequired")));
      } else if (file.size > 102400) {
        dispatch(showError(t("error.uploadPngFileSize", { value: 102400 })));
      } else {
        uploadLogo({ clientAppId: clientAppId, logo: file });
      }
    }
  };

  // akce po ulozeni dat
  useEffect(() => {
    if (uploadLogoResult.isSuccess) {
      dispatch(showInfo(t("dev.applications.info.logoUploaded")));
    }
  }, [dispatch, t, uploadLogoResult]);

  return (
    <Container fluid>
      <h1>{t("dev.applications.header")}</h1>
      <Row xs={1} md={2} lg={3}>
        {applications?.map((a, index) => (
          <Col key={index}>
            <Card className="m-1">
              <Card.Body>
                <input
                  ref={(element) => {
                    if (element) inputRefs.current[index] = element;
                  }}
                  className="d-none"
                  type="file"
                  onChange={() => handleUploadFile(index, a.id)}
                />
                <Dropdown className="float-end">
                  <Dropdown.Toggle variant="light" id="dropdown-basic">
                    <ThreeDotsVertical />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate("/dev/applications/" + a.id);
                      }}
                    >
                      {t("button.edit")}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={(e) => {
                        e.stopPropagation();
                        handleUpload(index);
                      }}
                    >
                      {t("dev.applications.button.logo")}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate("/dev/applications/" + a.id + "/permissions");
                      }}
                    >
                      {t("dev.applications.button.permissions")}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Card.Title>{a.title}</Card.Title>
                <Card.Subtitle>
                  {a.web && <a href={a.web}>{a.web}</a>}
                  {!a.web && <span>&nbsp;</span>}
                </Card.Subtitle>
                <Card.Text className="mt-2">
                  {a.logo && (
                    <Image
                      style={{ maxHeight: 74 }}
                      src={"data:image/png;base64," + (a.logo as unknown as string)}
                      alt="logo"
                      className="float-start me-2"
                      thumbnail={true}
                    />
                  )}
                  {!a.logo && <Image src="/noimage.png" alt="logo" className="float-start me-2" thumbnail={true} />}
                  {a.description}
                </Card.Text>
                <Row className="justify-content-between">
                  <Col xs="auto"></Col>
                  <Col xs="auto" className="align-self-end">
                    <Button variant="light" onClick={() => navigate("/dev/applications/" + a.id + "/services")}>
                      {t("dev.applications.button.services")}
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}
