import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Error } from "../../api/dev";

type SliceState = { show: boolean; error: boolean; text: string | undefined };

const initialState: SliceState = {
  show: false,
  error: false,
  text: undefined,
};

export const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    showInfo: (state, action: PayloadAction<string>) => {
      state.show = true;
      state.text = action.payload;
      state.error = false;
    },
    showError: (state, action: PayloadAction<string | Error>) => {
      if (typeof action.payload === "string") {
        state.text = action.payload;
      } else {
        state.text = action.payload.message;
      }
      state.show = true;
      state.error = true;
    },
    hide: (state) => {
      state.show = false;
      state.error = false;
      state.text = undefined;
    },
  },
});

export const { showInfo, showError, hide } = messageSlice.actions;

export default messageSlice.reducer;
