import { inbusSplitApi as api } from "./inbusApi";
export const addTagTypes = ["Application", "DeveloperAccount", "Organisation", "Service"] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      findApplicationDevelopers: build.query<FindApplicationDevelopersApiResponse, FindApplicationDevelopersApiArg>({
        query: (queryArg) => ({ url: `/admin/applications/${queryArg.clientAppId}/developers` }),
        providesTags: ["Application"],
      }),
      addApplicationDeveloper: build.mutation<AddApplicationDeveloperApiResponse, AddApplicationDeveloperApiArg>({
        query: (queryArg) => ({
          url: `/admin/applications/${queryArg.clientAppId}/developers`,
          method: "POST",
          body: queryArg.developerBase,
        }),
        invalidatesTags: ["Application"],
      }),
      findApplications: build.query<FindApplicationsApiResponse, FindApplicationsApiArg>({
        query: (queryArg) => ({
          url: `/admin/applications`,
          params: { search: queryArg.search, offset: queryArg.offset, limit: queryArg.limit },
        }),
        providesTags: ["Application"],
      }),
      createApplication: build.mutation<CreateApplicationApiResponse, CreateApplicationApiArg>({
        query: (queryArg) => ({ url: `/admin/applications`, method: "POST", body: queryArg.clientAppBase }),
        invalidatesTags: ["Application"],
      }),
      findApplication: build.query<FindApplicationApiResponse, FindApplicationApiArg>({
        query: (queryArg) => ({ url: `/admin/applications/${queryArg.clientAppId}` }),
        providesTags: ["Application"],
      }),
      saveApplication: build.mutation<SaveApplicationApiResponse, SaveApplicationApiArg>({
        query: (queryArg) => ({
          url: `/admin/applications/${queryArg.clientAppId}`,
          method: "PUT",
          body: queryArg.clientAppBase,
        }),
        invalidatesTags: ["Application"],
      }),
      removeApplication: build.mutation<RemoveApplicationApiResponse, RemoveApplicationApiArg>({
        query: (queryArg) => ({ url: `/admin/applications/${queryArg.clientAppId}`, method: "DELETE" }),
        invalidatesTags: ["Application"],
      }),
      findApplicationGrantTypes: build.query<FindApplicationGrantTypesApiResponse, FindApplicationGrantTypesApiArg>({
        query: (queryArg) => ({ url: `/admin/applications/${queryArg.clientAppId}/grantTypes` }),
        providesTags: ["Application"],
      }),
      findApplicationScopes: build.query<FindApplicationScopesApiResponse, FindApplicationScopesApiArg>({
        query: (queryArg) => ({ url: `/admin/applications/${queryArg.clientAppId}/scopes` }),
        providesTags: ["Application"],
      }),
      findApplicationServices: build.query<FindApplicationServicesApiResponse, FindApplicationServicesApiArg>({
        query: (queryArg) => ({ url: `/admin/applications/${queryArg.clientAppId}/services` }),
        providesTags: ["Application"],
      }),
      saveApplicationDeveloper: build.mutation<SaveApplicationDeveloperApiResponse, SaveApplicationDeveloperApiArg>({
        query: (queryArg) => ({
          url: `/admin/applications/${queryArg.clientAppId}/developers/${queryArg.developerId}`,
          method: "PUT",
          body: queryArg.developerBase,
        }),
        invalidatesTags: ["Application"],
      }),
      removeApplicationDeveloper: build.mutation<
        RemoveApplicationDeveloperApiResponse,
        RemoveApplicationDeveloperApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/applications/${queryArg.clientAppId}/developers/${queryArg.developerId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Application"],
      }),
      uploadApplicationLogo: build.mutation<UploadApplicationLogoApiResponse, UploadApplicationLogoApiArg>({
        query: (queryArg) => ({
          url: `/admin/applications/${queryArg.clientAppId}/logo`,
          method: "POST",
          params: { logo: queryArg.logo },
        }),
        invalidatesTags: ["Application"],
      }),
      findDeveloperAccounts: build.query<FindDeveloperAccountsApiResponse, FindDeveloperAccountsApiArg>({
        query: (queryArg) => ({ url: `/admin/developers`, params: { search: queryArg.search, limit: queryArg.limit } }),
        providesTags: ["DeveloperAccount"],
      }),
      createDeveloperAccount: build.mutation<CreateDeveloperAccountApiResponse, CreateDeveloperAccountApiArg>({
        query: (queryArg) => ({ url: `/admin/developers`, method: "POST", body: queryArg.developerAccountBase }),
        invalidatesTags: ["DeveloperAccount"],
      }),
      findDeveloperAccount: build.query<FindDeveloperAccountApiResponse, FindDeveloperAccountApiArg>({
        query: (queryArg) => ({ url: `/admin/developers/${queryArg.developerAccountId}` }),
        providesTags: ["DeveloperAccount"],
      }),
      saveDeveloperAccount: build.mutation<SaveDeveloperAccountApiResponse, SaveDeveloperAccountApiArg>({
        query: (queryArg) => ({
          url: `/admin/developers/${queryArg.developerAccountId}`,
          method: "PUT",
          body: queryArg.developerAccountBase,
        }),
        invalidatesTags: ["DeveloperAccount"],
      }),
      removeDeveloperAccount: build.mutation<RemoveDeveloperAccountApiResponse, RemoveDeveloperAccountApiArg>({
        query: (queryArg) => ({ url: `/admin/developers/${queryArg.developerAccountId}`, method: "DELETE" }),
        invalidatesTags: ["DeveloperAccount"],
      }),
      adminOpenapi: build.query<AdminOpenapiApiResponse, AdminOpenapiApiArg>({
        query: (queryArg) => ({ url: `/admin/openapi`, params: { scope: queryArg.scope } }),
      }),
      findOrganisations: build.query<FindOrganisationsApiResponse, FindOrganisationsApiArg>({
        query: () => ({ url: `/admin/organisations` }),
        providesTags: ["Organisation"],
      }),
      findServices: build.query<FindServicesApiResponse, FindServicesApiArg>({
        query: () => ({ url: `/admin/services` }),
        providesTags: ["Service"],
      }),
      createService: build.mutation<CreateServiceApiResponse, CreateServiceApiArg>({
        query: (queryArg) => ({ url: `/admin/services`, method: "POST", body: queryArg.service }),
        invalidatesTags: ["Service"],
      }),
      findServiceOrganisationServices: build.query<
        FindServiceOrganisationServicesApiResponse,
        FindServiceOrganisationServicesApiArg
      >({
        query: (queryArg) => ({ url: `/admin/services/${queryArg.serviceId}/organisations` }),
        providesTags: ["Service"],
      }),
      saveServiceOrganisationServices: build.mutation<
        SaveServiceOrganisationServicesApiResponse,
        SaveServiceOrganisationServicesApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/services/${queryArg.serviceId}/organisations`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["Service"],
      }),
      createServiceOrganisationService: build.mutation<
        CreateServiceOrganisationServiceApiResponse,
        CreateServiceOrganisationServiceApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/services/${queryArg.serviceId}/organisations`,
          method: "POST",
          body: queryArg.organisationServiceBase,
        }),
        invalidatesTags: ["Service"],
      }),
      findServiceScopes: build.query<FindServiceScopesApiResponse, FindServiceScopesApiArg>({
        query: (queryArg) => ({ url: `/admin/services/${queryArg.serviceId}/scopes` }),
        providesTags: ["Service"],
      }),
      createServiceScope: build.mutation<CreateServiceScopeApiResponse, CreateServiceScopeApiArg>({
        query: (queryArg) => ({
          url: `/admin/services/${queryArg.serviceId}/scopes`,
          method: "POST",
          body: queryArg.scope,
        }),
        invalidatesTags: ["Service"],
      }),
      findService: build.query<FindServiceApiResponse, FindServiceApiArg>({
        query: (queryArg) => ({ url: `/admin/services/${queryArg.serviceId}` }),
        providesTags: ["Service"],
      }),
      saveService: build.mutation<SaveServiceApiResponse, SaveServiceApiArg>({
        query: (queryArg) => ({ url: `/admin/services/${queryArg.serviceId}`, method: "PUT", body: queryArg.service }),
        invalidatesTags: ["Service"],
      }),
      removeService: build.mutation<RemoveServiceApiResponse, RemoveServiceApiArg>({
        query: (queryArg) => ({ url: `/admin/services/${queryArg.serviceId}`, method: "DELETE" }),
        invalidatesTags: ["Service"],
      }),
      findServiceScope: build.query<FindServiceScopeApiResponse, FindServiceScopeApiArg>({
        query: (queryArg) => ({ url: `/admin/services/${queryArg.serviceId}/scopes/${queryArg.scopeId}` }),
        providesTags: ["Service"],
      }),
      saveServiceScope: build.mutation<SaveServiceScopeApiResponse, SaveServiceScopeApiArg>({
        query: (queryArg) => ({
          url: `/admin/services/${queryArg.serviceId}/scopes/${queryArg.scopeId}`,
          method: "PUT",
          body: queryArg.scope,
        }),
        invalidatesTags: ["Service"],
      }),
      removeServiceScope: build.mutation<RemoveServiceScopeApiResponse, RemoveServiceScopeApiArg>({
        query: (queryArg) => ({
          url: `/admin/services/${queryArg.serviceId}/scopes/${queryArg.scopeId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Service"],
      }),
      findServiceScopeClientApps: build.query<FindServiceScopeClientAppsApiResponse, FindServiceScopeClientAppsApiArg>({
        query: (queryArg) => ({ url: `/admin/services/${queryArg.serviceId}/scopes/${queryArg.scopeId}/clientApps` }),
        providesTags: ["Service"],
      }),
      findServicesPreview: build.query<FindServicesPreviewApiResponse, FindServicesPreviewApiArg>({
        query: () => ({ url: `/admin/services/preview` }),
        providesTags: ["Service"],
      }),
      saveServiceOrganisationService: build.mutation<
        SaveServiceOrganisationServiceApiResponse,
        SaveServiceOrganisationServiceApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/services/${queryArg.serviceId}/organisations/${queryArg.organisationServiceId}`,
          method: "PUT",
          body: queryArg.organisationServiceBase,
        }),
        invalidatesTags: ["Service"],
      }),
      removeServiceOrganisationService: build.mutation<
        RemoveServiceOrganisationServiceApiResponse,
        RemoveServiceOrganisationServiceApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/services/${queryArg.serviceId}/organisations/${queryArg.organisationServiceId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Service"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as inbusApi };
export type FindApplicationDevelopersApiResponse = /** status 200 Return application developers */ Developer[];
export type FindApplicationDevelopersApiArg = {
  clientAppId: number;
};
export type AddApplicationDeveloperApiResponse = unknown;
export type AddApplicationDeveloperApiArg = {
  clientAppId: number;
  developerBase: DeveloperBase;
};
export type FindApplicationsApiResponse = /** status 200 undefined */ ClientApp[];
export type FindApplicationsApiArg = {
  search?: string;
  offset?: number;
  limit?: number;
};
export type CreateApplicationApiResponse = /** status 200 undefined */ DeveloperAccount;
export type CreateApplicationApiArg = {
  clientAppBase: ClientAppBase;
};
export type FindApplicationApiResponse = /** status 200 undefined */ ClientAppAdmin;
export type FindApplicationApiArg = {
  clientAppId: number;
};
export type SaveApplicationApiResponse = /** status 200 undefined */ ClientAppAdmin;
export type SaveApplicationApiArg = {
  clientAppId: number;
  clientAppBase: ClientAppBase;
};
export type RemoveApplicationApiResponse = unknown;
export type RemoveApplicationApiArg = {
  clientAppId: number;
};
export type FindApplicationGrantTypesApiResponse = /** status 200 undefined */ GrantType[];
export type FindApplicationGrantTypesApiArg = {
  clientAppId: number;
};
export type FindApplicationScopesApiResponse = /** status 200 undefined */ Scope[];
export type FindApplicationScopesApiArg = {
  clientAppId: number;
};
export type FindApplicationServicesApiResponse = /** status 200 undefined */ ApplicationService[];
export type FindApplicationServicesApiArg = {
  clientAppId: number;
};
export type SaveApplicationDeveloperApiResponse = unknown;
export type SaveApplicationDeveloperApiArg = {
  clientAppId: number;
  developerId: number;
  developerBase: DeveloperBase;
};
export type RemoveApplicationDeveloperApiResponse = unknown;
export type RemoveApplicationDeveloperApiArg = {
  clientAppId: number;
  developerId: number;
};
export type UploadApplicationLogoApiResponse = unknown;
export type UploadApplicationLogoApiArg = {
  logo: any;
  clientAppId: number;
};
export type FindDeveloperAccountsApiResponse = /** status 200 undefined */ DeveloperAccount[];
export type FindDeveloperAccountsApiArg = {
  search?: string;
  limit?: number;
};
export type CreateDeveloperAccountApiResponse = /** status 200 undefined */ DeveloperAccount;
export type CreateDeveloperAccountApiArg = {
  developerAccountBase: DeveloperAccountBase;
};
export type FindDeveloperAccountApiResponse = /** status 200 undefined */ DeveloperAccount;
export type FindDeveloperAccountApiArg = {
  developerAccountId: number;
};
export type SaveDeveloperAccountApiResponse = /** status 200 undefined */ DeveloperAccount;
export type SaveDeveloperAccountApiArg = {
  developerAccountId: number;
  developerAccountBase: DeveloperAccountBase;
};
export type RemoveDeveloperAccountApiResponse = unknown;
export type RemoveDeveloperAccountApiArg = {
  developerAccountId: number;
};
export type AdminOpenapiApiResponse = unknown;
export type AdminOpenapiApiArg = {
  scope?: string;
};
export type FindOrganisationsApiResponse = /** status 200 undefined */ Organisation[];
export type FindOrganisationsApiArg = void;
export type FindServicesApiResponse = /** status 200 undefined */ Service[];
export type FindServicesApiArg = void;
export type CreateServiceApiResponse = /** status 200 undefined */ Service;
export type CreateServiceApiArg = {
  service: Service;
};
export type FindServiceOrganisationServicesApiResponse = /** status 200 undefined */ OrganisationServiceBase[];
export type FindServiceOrganisationServicesApiArg = {
  serviceId: number;
};
export type SaveServiceOrganisationServicesApiResponse = unknown;
export type SaveServiceOrganisationServicesApiArg = {
  serviceId: number;
  body: OrganisationServiceBase[];
};
export type CreateServiceOrganisationServiceApiResponse = /** status 200 undefined */ Scope;
export type CreateServiceOrganisationServiceApiArg = {
  serviceId: number;
  organisationServiceBase: OrganisationServiceBase;
};
export type FindServiceScopesApiResponse = /** status 200 undefined */ Scope[];
export type FindServiceScopesApiArg = {
  serviceId: number;
};
export type CreateServiceScopeApiResponse = /** status 200 undefined */ Scope;
export type CreateServiceScopeApiArg = {
  serviceId: number;
  scope: Scope;
};
export type FindServiceApiResponse = /** status 200 undefined */ Service;
export type FindServiceApiArg = {
  serviceId: number;
};
export type SaveServiceApiResponse = /** status 200 undefined */ Service;
export type SaveServiceApiArg = {
  serviceId: number;
  service: Service;
};
export type RemoveServiceApiResponse = unknown;
export type RemoveServiceApiArg = {
  serviceId: number;
};
export type FindServiceScopeApiResponse = /** status 200 undefined */ Scope;
export type FindServiceScopeApiArg = {
  serviceId: number;
  scopeId: number;
};
export type SaveServiceScopeApiResponse = /** status 200 undefined */ Scope;
export type SaveServiceScopeApiArg = {
  serviceId: number;
  scopeId: number;
  scope: Scope;
};
export type RemoveServiceScopeApiResponse = unknown;
export type RemoveServiceScopeApiArg = {
  serviceId: number;
  scopeId: number;
};
export type FindServiceScopeClientAppsApiResponse = /** status 200 undefined */ ClientApp[];
export type FindServiceScopeClientAppsApiArg = {
  serviceId: number;
  scopeId: number;
};
export type FindServicesPreviewApiResponse = /** status 200 undefined */ ApplicationService[];
export type FindServicesPreviewApiArg = void;
export type SaveServiceOrganisationServiceApiResponse = /** status 200 undefined */ Scope;
export type SaveServiceOrganisationServiceApiArg = {
  serviceId: number;
  organisationServiceId: number;
  organisationServiceBase: OrganisationServiceBase;
};
export type RemoveServiceOrganisationServiceApiResponse = unknown;
export type RemoveServiceOrganisationServiceApiArg = {
  serviceId: number;
  organisationServiceId: number;
};
export type Developer = {
  id?: number;
  version?: number;
  admin?: boolean;
  login: string;
  firstName: string;
  lastName: string;
  organisation?: string;
};
export type Error = {
  code: string;
  message?: string;
};
export type DeveloperBase = {
  id?: number;
  version?: number;
  admin?: boolean;
  developerAccountEmail?: string;
};
export type GrantType = {
  id?: number;
  key: string;
  title: string;
  tokenExpiration?: number;
};
export type ClientApp = {
  id?: number;
  title: string;
  description: string;
  logo?: string[];
  web?: string;
  grantTypes: GrantType[];
  developers: Developer[];
};
export type DeveloperAccount = {
  version?: number;
  id?: number;
  login: string;
  firstName: string;
  lastName: string;
  organisation?: string;
  phone?: string;
};
export type ClientAppBase = {
  id?: number;
  version?: number;
  title: string;
  description: string;
  descriptionEN?: string;
  logo?: string[];
  web?: string;
  clientId?: string;
  clientSecret?: string;
  redirectUris: string[];
  scopeIds?: number[];
  grantTypeIds?: number[];
};
export type ClientAppAdmin = {
  id?: number;
  version?: number;
  title: string;
  description: string;
  descriptionEN?: string;
  logo?: string[];
  web?: string;
  clientId?: string;
  clientSecret?: string;
  grantTypes: GrantType[];
  redirectUris: string[];
  developers: Developer[];
};
export type Scope = {
  id?: number;
  version?: number;
  title: string;
  readonly: boolean;
  apidoc?: string;
  requireUserIdentity: boolean;
  respondsToUserIdentity: boolean;
  description: string;
  descriptionEN?: string;
  restrictionRegex?: string;
};
export type ApplicationServiceScope = {
  id?: number;
  title: string;
  readonly: boolean;
  requireUserIdentity: boolean;
  respondsToUserIdentity: boolean;
  description: string;
};
export type ApplicationService = {
  id?: number;
  title: string;
  description: string;
  contextPath: string;
  apiVersion: number;
  scopes: ApplicationServiceScope[];
};
export type DeveloperAccountBase = {
  version?: number;
  id?: number;
  login: string;
  firstName: string;
  lastName: string;
  organisation?: string;
  phone?: string;
  password?: string;
};
export type Organisation = {
  id?: number;
  version?: number;
  title: string;
  titleEN?: string;
  key: string;
  logo?: string[];
  web?: string;
};
export type Service = {
  id?: number;
  version?: number;
  title: string;
  titleEN?: string;
  description: string;
  descriptionEN?: string;
  contextPath: string;
  apiVersion: number;
};
export type OrganisationServiceBase = {
  id?: number;
  version?: number;
  url: string;
  organisationId: number;
};
export const {
  useFindApplicationDevelopersQuery,
  useAddApplicationDeveloperMutation,
  useFindApplicationsQuery,
  useCreateApplicationMutation,
  useFindApplicationQuery,
  useSaveApplicationMutation,
  useRemoveApplicationMutation,
  useFindApplicationGrantTypesQuery,
  useFindApplicationScopesQuery,
  useFindApplicationServicesQuery,
  useSaveApplicationDeveloperMutation,
  useRemoveApplicationDeveloperMutation,
  useUploadApplicationLogoMutation,
  useFindDeveloperAccountsQuery,
  useCreateDeveloperAccountMutation,
  useFindDeveloperAccountQuery,
  useSaveDeveloperAccountMutation,
  useRemoveDeveloperAccountMutation,
  useAdminOpenapiQuery,
  useFindOrganisationsQuery,
  useFindServicesQuery,
  useCreateServiceMutation,
  useFindServiceOrganisationServicesQuery,
  useSaveServiceOrganisationServicesMutation,
  useCreateServiceOrganisationServiceMutation,
  useFindServiceScopesQuery,
  useCreateServiceScopeMutation,
  useFindServiceQuery,
  useSaveServiceMutation,
  useRemoveServiceMutation,
  useFindServiceScopeQuery,
  useSaveServiceScopeMutation,
  useRemoveServiceScopeMutation,
  useFindServiceScopeClientAppsQuery,
  useFindServicesPreviewQuery,
  useSaveServiceOrganisationServiceMutation,
  useRemoveServiceOrganisationServiceMutation,
} = injectedRtkApi;
