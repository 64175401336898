import { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useFindMyApplicationQuery, useFindMyApplicationScopeQuery } from "../../../api/dev";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";

/**
 *	Uprava aplikace
 */
export function Api() {
  // preklady
  const { t } = useTranslation();

  let { clientAppId, scopeId } = useParams();

  let id = clientAppId ? parseInt(clientAppId) : 0;
  let serviceScopeId = scopeId ? parseInt(scopeId) : 0;

  const { data: application } = useFindMyApplicationQuery({ clientAppId: id }, { skip: id === 0 });

  const { data: scope } = useFindMyApplicationScopeQuery(
    { clientAppId: id, scopeId: serviceScopeId },
    { skip: serviceScopeId === 0 }
  );

  // titulek stranky
  useEffect(() => {
    document.title = t("app.pageTitle") + " - " + t("dev.api.header");
  });

  return (
    <Container fluid>
      <h1>
        {application?.title}
        <span className="header-type">{t("dev.api.header")}</span>
      </h1>

      {scope && (
        <SwaggerUI
          url={"/rest/dev/openapi?scope=" + scope.title}
          supportedSubmitMethods={["get", "options", "head"]}
          onComplete={(system) => {
            system.initOAuth({
              clientId: application?.clientId,
              clientSecret: application?.clientSecret,
              appName: application?.title,
              scopes: scope.title,
            });
          }}
        />
      )}
    </Container>
  );
}
