import { useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useChangePasswordMutation, ChangePassword } from "../../../api/dev";
import { useAppDispatch } from "../../../app/hooks";
import { showInfo } from "../../message";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import UI from "../../ui";

type ChangePasswordForm = ChangePassword & { newPassword2: string };

/**
 *	Editace profilu vyvojare
 */
export function Password() {
  const dispatch = useAppDispatch();

  // preklady
  const { t } = useTranslation();

  // schema validace
  const schema: yup.SchemaOf<ChangePasswordForm> = yup
    .object({
      oldPassword: yup.string().required(),
      newPassword: yup.string().min(8).required(),
      newPassword2: yup
        .string()
        .required()
        .test("confirm", t("dev.password.validation.notEqual"), (value, context) => {
          return value === context.parent.newPassword;
        }),
    })
    .required();

  const navigate = useNavigate();

  // validace formulare
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ChangePasswordForm>({
    defaultValues: {
      oldPassword: "",
      newPassword: "",
      newPassword2: "",
    },
    resolver: yupResolver(schema, { stripUnknown: true }),
  });

  // akce zmeny hesla
  const [changePassword, changePasswordResult] = useChangePasswordMutation();

  // titulek stranky
  useEffect(() => {
    document.title = t("app.pageTitle") + " - " + t("dev.password.header");
  });

  // akce po ulozeni dat
  useEffect(() => {
    if (changePasswordResult.isSuccess) {
      dispatch(showInfo(t("info.saved")));
      navigate("/dev/profile");
    }
  }, [dispatch, t, changePasswordResult, navigate]);

  // ulozeni formulare
  const onSubmit = (data: ChangePassword) => {
    changePassword({ changePassword: data });
  };

  return (
    <div>
      <h1>{t("dev.password.header")}</h1>
      <Row className="justify-content-md-center">
        <Col xs="12" md="10" lg="8">
          <Form noValidate onSubmit={handleSubmit(onSubmit)}>
            <UI.Input
              control={control}
              name="oldPassword"
              label={t("dev.password.label.oldPassword")}
              required={true}
              type="password"
            />
            <UI.Input
              control={control}
              name="newPassword"
              label={t("dev.password.label.newPassword")}
              required={true}
              type="password"
            />
            <UI.Input
              control={control}
              name="newPassword2"
              label={t("dev.password.label.newPassword2")}
              required={true}
              type="password"
            />
            <UI.ActionButton label={t("button.save")} isLoading={changePasswordResult.isLoading} />{" "}
          </Form>
        </Col>
      </Row>
    </div>
  );
}
