import { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";
import { useFindServiceScopeQuery, useFindApplicationQuery } from "../../../api/admin";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";

/**
 *	Uprava aplikace
 */
export function Api() {
  // preklady
  const { t } = useTranslation();

  let { serviceId, scopeId } = useParams();

  let [searchParams] = useSearchParams();

  const { data: scope } = useFindServiceScopeQuery(
    { serviceId: parseInt(serviceId!), scopeId: parseInt(scopeId!) },
    { skip: !serviceId || !scopeId }
  );

  // ziskani parametru z url
  const { data: application } = useFindApplicationQuery(
    { clientAppId: parseInt(searchParams.get("applicationId")!) },
    { skip: !searchParams.has("applicationId") }
  );

  // titulek stranky
  useEffect(() => {
    document.title = t("app.pageTitle") + " - " + t("admin.api.header");
  });

  return (
    <Container fluid>
      <h1>
        {scope?.title}
        <span className="header-type">{t("admin.api.header")}</span>
      </h1>

      {scope && (
        <SwaggerUI
          url={"/rest/admin/openapi?scope=" + scope.title}
          supportedSubmitMethods={["get", "options", "head"]}
          onComplete={(system) => {
            system.initOAuth({
              clientId: application?.clientId,
              clientSecret: application?.clientSecret,
              appName: application?.title,
              scopes: scope.title,
            });
          }}
        />
      )}
    </Container>
  );
}
