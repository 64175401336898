import { inbusApi, LoginDeveloperApiArg } from "./authBase";

/**
 * Rozsireni / Zmena vygenerovaneho API
 */
const injectedRtkApi = inbusApi.enhanceEndpoints({
  endpoints: {
    loginDeveloper: {
      query(queryArg: LoginDeveloperApiArg) {
        let form = new URLSearchParams();
        form.append("username", queryArg.body.username);
        form.append("password", queryArg.body.password);
        return {
          url: "/auth/loginDeveloper",
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: form,
        };
      },
    },
  },
});

export * from "./authBase";
export const { useLoginDeveloperMutation } = injectedRtkApi;
