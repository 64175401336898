import { useEffect } from "react";
import { Container, Form, Card, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  useFindApplicationScopesQuery,
  useSaveApplicationMutation,
  useFindServicesPreviewQuery,
  ClientAppAdmin,
  ClientAppBase,
} from "../../../api/admin";
import { useFindGrantTypesQuery } from "../../../api/common";
import { GrantType } from "../../../utils/consts";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { stripEmpty } from "../../../utils/form";
import * as yup from "yup";
import { Trash, Eye, PersonCheckFill, PersonFill } from "react-bootstrap-icons";
import { useAppDispatch } from "../../../app/hooks";
import { showInfo } from "../../message";
import UI from "../../ui";

// pro pouziti ve formulari
type ClientAppUForm = ClientAppBase & { formRedirectUris?: { uri: string }[] };

interface Props {
  application: ClientAppAdmin;
}

/**
 *	Sluzby aplikace
 */
export default function Services({ application }: Props) {
  const dispatch = useAppDispatch();
  // preklady
  const { t } = useTranslation();

  // schema validace
  const schema: yup.SchemaOf<ClientAppUForm> = yup
    .object({
      id: yup.number(),
      title: yup.string().trim().max(255).required(),
      titleEN: yup.string().trim().max(255),
      version: yup.number(), // potreba pro validaci na serveru (stripUnknown: true)
      description: yup.string().trim().max(4095).required(),
      descriptionEN: yup.string().trim().max(4095),
      logo: yup.mixed(),
      web: yup.string().url().optional().transform(stripEmpty),
      redirectUris: yup.array().of(yup.string().required()),
      clientId: yup.string(),
      clientSecret: yup.string(),
      scopeIds: yup.array().of(yup.number()).optional(),
      grantTypeIds: yup.array().of(yup.number()).optional(),
      formRedirectUris: yup
        .array()
        .of(
          yup.object({
            uri: yup
              .string()
              .test("url", t("field.string.url"), (value) => {
                // url() neumi validovat localhost
                if (!value) return true;
                try {
                  const valid = new URL(value!);
                  return Boolean(valid);
                } catch {
                  return false;
                }
              })
              .required(),
          })
        )
        .optional(), // pouze pro formular
    })
    .transform((o: ClientAppUForm) => {
      return { ...o, redirectUris: o.formRedirectUris!.map((e) => e.uri) };
    })
    .required();

  const { data: grantTypes } = useFindGrantTypesQuery();

  const { data: applicationScopes } = useFindApplicationScopesQuery({ clientAppId: application.id! });

  const { data: services } = useFindServicesPreviewQuery();

  // akce ulozeni profilu
  const [saveApplication, saveApplicationResult] = useSaveApplicationMutation();

  // validace formulare
  const { control, handleSubmit, reset, watch } = useForm<ClientAppUForm>({
    defaultValues: {
      grantTypeIds: application.grantTypes.map((e) => e.id),
      // konverze na parametr formRedirectUris, protoze react-hook-form neumi zpracovat flat pole
      formRedirectUris: application.redirectUris.map((e) => ({
        uri: e,
      })),
      ...application,
    },
    resolver: yupResolver(schema, { stripUnknown: true }),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "formRedirectUris",
  });

  // nacteni hodnot do formulare
  useEffect(() => {
    if (application) {
      // konverze na parametr formRedirectUris, protoze react-hook-form neumi zpracovat flat pole
      reset({
        grantTypeIds: application.grantTypes.map((e) => e.id),
        // konverze na parametr formRedirectUris, protoze react-hook-form neumi zpracovat flat pole
        formRedirectUris: application.redirectUris.map((e) => ({
          uri: e,
        })),
        scopeIds: applicationScopes?.map((s) => s.id),
        ...application,
      });
    }
  }, [reset, application, applicationScopes]);

  // akce po ulozeni dat
  useEffect(() => {
    if (saveApplicationResult.isSuccess) {
      dispatch(showInfo(t("info.saved")));
    }
  }, [dispatch, t, saveApplicationResult]);

  let authorizationCodeGrant = watch("grantTypeIds")?.includes(GrantType.AUTHORIZATION_CODE_GRANT);

  // ulozeni formulare
  const onSubmit = (data: ClientAppUForm) => {
    delete data.formRedirectUris;
    saveApplication({ clientAppId: application.id!, clientAppBase: data });
  };

  return (
    <Container fluid>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className="mb-3" controlId="formClientId">
          <Form.Label>{t("label.clientId")}</Form.Label>
          <Form.Control
            plaintext
            readOnly
            value={application?.clientId ? application.clientId : t("dev.application.text.generatedAfterSave")}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formClientSecret">
          <Form.Label>{t("label.clientSecret")}</Form.Label>
          <Form.Control
            plaintext
            readOnly
            value={application?.clientSecret ? application.clientSecret : t("dev.application.text.generatedAfterSave")}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formGrantType">
          <Form.Label>{t("label.grantType")}</Form.Label>
          {grantTypes?.map((g, index) => (
            <UI.Check
              key={index}
              className=""
              id={"grantType-" + index}
              control={control}
              name="grantTypeIds"
              label={g.title}
              value={g.id}
            />
          ))}
        </Form.Group>

        {authorizationCodeGrant && (
          <Form.Group className="mb-3" controlId="formRedirectUrls">
            <Form.Label>{t("label.redirectUris")}</Form.Label>
            <div>
              {fields.map((field, index) => {
                return (
                  <>
                    <UI.Input key={field.id} control={control} name={`formRedirectUris.${index}.uri`}>
                      <Button variant="outline-danger" onClick={() => remove(index)}>
                        <Trash />
                      </Button>
                    </UI.Input>
                  </>
                );
              })}
            </div>
            <Button variant="secondary" onClick={() => append({ uri: "" })}>
              {t("button.add")}
            </Button>
          </Form.Group>
        )}

        <h3>{t("dev.services.label.services")}</h3>
        {services?.map((service, index) => (
          <Card key={index} className="my-4">
            <Card.Header>
              <div>
                <strong>
                  {service.title} <span className="text-muted">(v{service.apiVersion})</span>
                </strong>
              </div>
              <em>{service.description}</em>
            </Card.Header>
            <Card.Body>
              <ul className="list-unstyled">
                {service.scopes.map((scope, index) => (
                  <li key={index}>
                    <UI.Check
                      key={index}
                      className=""
                      id={"grantType-" + index}
                      control={control}
                      name="scopeIds"
                      value={scope.id}
                      inline
                    />
                    <Link
                      to={"/admin/services/" + service.id + "/scopes/" + scope.id + "?applicationId=" + application.id}
                    >
                      {scope.title}
                    </Link>
                    {scope.readonly && <Eye className="mx-1 text-muted" title={t("title.scopeReadonly")} />}
                    {scope.respondsToUserIdentity && (
                      <PersonFill className="mx-1 text-muted" title={t("title.scopeRespondsToUserIdentity")} />
                    )}
                    {scope.requireUserIdentity && (
                      <PersonCheckFill className="mx-1 text-warning" title={t("title.scopeRequireUserIdentity")} />
                    )}
                    {" - "} {scope.description}
                  </li>
                ))}
              </ul>
            </Card.Body>
          </Card>
        ))}
        <UI.ActionButton label={t("button.save")} isLoading={saveApplicationResult.isLoading} />
      </Form>
    </Container>
  );
}
