import { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { useSaveServiceMutation, useCreateServiceMutation, Service } from "../../../api/admin";
import { useAppDispatch } from "../../../app/hooks";
import { showInfo } from "../../message";
import UI from "../../ui";
import * as yup from "yup";

// schema validace
const schema: yup.SchemaOf<Service> = yup
  .object({
    id: yup.number(),
    title: yup.string().trim().max(255).required(),
    titleEN: yup.string().trim().max(255),
    version: yup.number(), // potreba pro validaci na serveru (stripUnknown: true)
    description: yup.string().trim().max(4095).required(),
    descriptionEN: yup.string().trim().max(4095),
    contextPath: yup.string().trim().max(255).required(),
    apiVersion: yup.number().required(),
  })
  .required();

interface Props {
  service?: Service;
}

/**
 *	Uprava sluzby
 */
export default function Main({ service }: Props) {
  const dispatch = useAppDispatch();
  // preklady
  const { t } = useTranslation();

  const navigate = useNavigate();

  // validace formulare
  const { control, handleSubmit, reset } = useForm<Service>({
    defaultValues: {
      title: "",
      description: "",
      contextPath: "",
      apiVersion: 1,
    },
    resolver: yupResolver(schema, { stripUnknown: true }),
  });

  // akce vytvoreni sluzby
  const [createService, createServiceResult] = useCreateServiceMutation();

  // akce ulozeni sluzby
  const [saveService, saveServiceResult] = useSaveServiceMutation();

  // nacteni hodnot do formulare
  useEffect(() => {
    if (service) {
      reset(service);
    }
  }, [reset, service]);

  // akce po vytvoreni dat
  useEffect(() => {
    if (createServiceResult.isSuccess) {
      dispatch(showInfo(t("info.saved")));
      navigate("/admin/services/" + createServiceResult.data.id, { replace: true });
    }
  }, [dispatch, t, createServiceResult, navigate]);

  // akce po ulozeni dat
  useEffect(() => {
    if (saveServiceResult.isSuccess) {
      dispatch(showInfo(t("info.saved")));
    }
  }, [dispatch, t, saveServiceResult]);

  // ulozeni formulare
  const onSubmit = (data: Service) => {
    if (data.id && service?.id) {
      saveService({ serviceId: service.id, service: data });
    } else {
      createService({ service: data });
    }
  };

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      <UI.Input control={control} name="title" label={t("admin.service.label.title")} required={true} />
      <UI.Input control={control} name="titleEN" label={t("admin.service.label.title") + " (EN)"} />
      <UI.Input control={control} name="description" label={t("admin.service.label.description")} required={true} />
      <UI.Input control={control} name="descriptionEN" label={t("admin.service.label.description") + " (EN)"} />
      <UI.Input control={control} name="contextPath" label={t("admin.service.label.contextPath")} required={true} />
      <UI.Input control={control} name="apiVersion" label={t("admin.service.label.apiVersion")} required={true} />
      {!service?.id && (
        <UI.ActionButton type="submit" label={t("button.create")} isLoading={createServiceResult.isLoading} />
      )}
      {service?.id && (
        <UI.ActionButton type="submit" label={t("button.save")} isLoading={saveServiceResult.isLoading} />
      )}
    </Form>
  );
}
