import { inbusSplitApi as api } from "./inbusApi";
export const addTagTypes = ["GrantType"] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      findGrantType: build.query<FindGrantTypeApiResponse, FindGrantTypeApiArg>({
        query: (queryArg) => ({ url: `/grantTypes/${queryArg.grantTypeId}` }),
        providesTags: ["GrantType"],
      }),
      findGrantTypes: build.query<FindGrantTypesApiResponse, FindGrantTypesApiArg>({
        query: () => ({ url: `/grantTypes` }),
        providesTags: ["GrantType"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as inbusApi };
export type FindGrantTypeApiResponse = /** status 200 undefined */ GrantType;
export type FindGrantTypeApiArg = {
  grantTypeId: number;
};
export type FindGrantTypesApiResponse = /** status 200 undefined */ GrantType[];
export type FindGrantTypesApiArg = void;
export type GrantType = {
  id?: number;
  key: string;
  title: string;
  tokenExpiration?: number;
};
export type Error = {
  code: string;
  message?: string;
};
export const { useFindGrantTypeQuery, useFindGrantTypesQuery } = injectedRtkApi;
