import { Nav } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

/**
 *	Menu
 */
export function Menu() {
  // preklady
  const { t } = useTranslation();

  return (
    <Nav id="main-menu" className="ms-auto">
      <NavLink className="nav-link" to="/admin/applications">
        {t("admin.menu.applications")}
      </NavLink>
      <NavLink className="nav-link" to="/admin/developers">
        {t("admin.menu.developers")}
      </NavLink>
      <NavLink className="nav-link" to="/admin/services">
        {t("admin.menu.services")}
      </NavLink>
    </Nav>
  );
}
