import { useEffect } from "react";
import { Container, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { PencilSquare, PlusSquare } from "react-bootstrap-icons";
import { useFindServicesQuery } from "../../../api/admin";
import { tl } from "../../../i18n";
/**
 *	Aplikace
 */
export function Services() {
  // preklady
  const { t } = useTranslation();

  const { data: services } = useFindServicesQuery();

  useEffect(() => {
    document.title = t("app.pageTitle") + " - " + t("admin.services.header");
  });

  return (
    <Container fluid>
      <h1>{t("admin.services.header")}</h1>
      <Table hover responsive="md">
        <thead>
          <tr>
            <th>{t("admin.services.column.title")}</th>
            <th>{t("admin.services.column.description")}</th>
            <th>{t("admin.services.column.contextPath")}</th>
            <th>{t("admin.services.column.apiVersion")}</th>
            <th className="text-end">
              <Link to={"/admin/services/create"}>
                <PlusSquare />
              </Link>
            </th>
          </tr>
        </thead>
        <tbody>
          {services?.map((s, index) => (
            <tr key={index}>
              <td>{tl(s.title,s.titleEN)}</td>
              <td>{tl(s.description, s.descriptionEN)}</td>
              <td>{s.contextPath}</td>
              <td>{s.apiVersion}</td>
              <td className="text-end">
                <Link to={"/admin/services/" + s.id}>
                  <PencilSquare />
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}
