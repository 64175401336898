import { useEffect } from "react";
import { Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCreateServiceScopeMutation, useSaveServiceScopeMutation, Scope } from "../../../api/admin";
import { useAppDispatch } from "../../../app/hooks";
import { showInfo } from "../../message";
import UI from "../../ui";
import * as yup from "yup";
import { stripEmpty } from "../../../utils/form";

interface Props {
  serviceId: number;
  scope: Scope;
  show: boolean;
  onHide: () => void;
}
/**
 *	Opravneni aplikace
 */
export default function ScopeModal({ serviceId, scope, show, onHide }: Props) {
  const dispatch = useAppDispatch();
  // preklady
  const { t } = useTranslation();

  // schema validace
  const schema: yup.SchemaOf<Scope> = yup.object({
    id: yup.number(),
    version: yup.number(), // potreba pro validaci na serveru (stripUnknown: true)
    readonly: yup.boolean().required(),
    requireUserIdentity: yup.boolean().required(),
    respondsToUserIdentity: yup.boolean().required(),
    title: yup.string().trim().max(255).required(),
    description: yup.string().trim().max(4095).required(),
    descriptionEN: yup.string().trim().max(4095),
    apidoc: yup
      .string()
      .test("url", t("field.string.url"), (value) => {
        // url() neumi validovat localhost
        if (!value) return true;
        try {
          const valid = new URL(value!);
          return Boolean(valid);
        } catch {
          return false;
        }
      })
      .max(255)
      .optional()
      .transform(stripEmpty),
    restrictionRegex: yup.string().trim().max(1023).optional().transform(stripEmpty),
  });

  // validace formulare
  const { control, handleSubmit, reset } = useForm<Scope>({
    defaultValues: scope,
    resolver: yupResolver(schema, { stripUnknown: true }),
  });

  // akce
  const [saveServiceScope, saveServiceScopeResult] = useSaveServiceScopeMutation();
  const [createServiceScope, createServiceScopeResult] = useCreateServiceScopeMutation();

  // ulozeni formulare
  const onSubmit = (data: Scope) => {
    if (data.id) {
      saveServiceScope({ serviceId: serviceId, scopeId: data.id, scope: data });
    } else {
      createServiceScope({ serviceId: serviceId, scope: data });
    }
  };

  // zmena objektu scope
  useEffect(() => {
    reset(scope);
  }, [reset, scope]);

  // akce po ulozeni dat
  useEffect(() => {
    if (createServiceScopeResult.isSuccess || saveServiceScopeResult.isSuccess) {
      onHide();
      dispatch(showInfo(t("info.saved")));
      reset();
      createServiceScopeResult.reset();
      saveServiceScopeResult.reset();
    }
  }, [dispatch, t, createServiceScopeResult, saveServiceScopeResult, reset, onHide]);

  return (
    <Modal show={show} onHide={onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{t("admin.service.modal.scopeHeader")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UI.Input control={control} name="title" label={t("admin.service.label.scopeTitle")} required={true} />
          <UI.Check control={control} name="readonly" label={t("admin.service.label.readonly")} />
          <UI.Check
            control={control}
            name="respondsToUserIdentity"
            label={t("admin.service.label.respondsToUserIdentity")}
          />
          <UI.Check control={control} name="requireUserIdentity" label={t("admin.service.label.requireUserIdentity")} />
          <UI.Input
            control={control}
            name="description"
            rows={3}
            label={t("admin.service.label.scopeDescription")}
            required={true}
          />
          <UI.Input
            control={control}
            name="descriptionEN"
            rows={3}
            label={t("admin.service.label.scopeDescription") + " (EN)"}
          />
          <UI.Input control={control} name="apidoc" label={t("admin.service.label.apidoc")} />
          <UI.Input control={control} name="restrictionRegex" label={t("admin.service.label.restrictionRegex")} />
        </Modal.Body>
        <Modal.Footer>
          {!serviceId && (
            <UI.ActionButton type="submit" label={t("button.create")} isLoading={createServiceScopeResult.isLoading} />
          )}
          {serviceId && (
            <UI.ActionButton type="submit" label={t("button.save")} isLoading={saveServiceScopeResult.isLoading} />
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
