import { useEffect } from "react";
import { Container, Form, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams, Link } from "react-router-dom";
import { useFindMyApplicationQuery, useFindMyApplicationServicesQuery } from "../../../api/dev";
import { GrantType } from "../../../utils/consts";
import { Eye, PersonCheckFill, PersonFill } from "react-bootstrap-icons";
/**
 *	Uprava aplikace
 */
export function Services() {
  // preklady
  const { t } = useTranslation();

  let { clientAppId } = useParams();

  let id = clientAppId ? parseInt(clientAppId) : 0;

  const { data: application } = useFindMyApplicationQuery({ clientAppId: id }, { skip: id === 0 });

  const { data: services } = useFindMyApplicationServicesQuery({ clientAppId: id }, { skip: id === 0 });

  // titulek stranky
  useEffect(() => {
    document.title = t("app.pageTitle") + " - " + t("dev.services.header");
  });

  let authorizationCodeGrant = false;
  application?.grantTypes.forEach((g) => {
    if (g.id === GrantType.AUTHORIZATION_CODE_GRANT) authorizationCodeGrant = true;
  });

  return (
    <Container fluid>
      <h1>
        {application?.title} <span className="header-type">{t("dev.services.header")}</span>
      </h1>

      <Form.Group className="mb-3" controlId="formClientId">
        <Form.Label>{t("label.clientId")}</Form.Label>
        <Form.Control
          plaintext
          readOnly
          value={application?.clientId ? application.clientId : t("dev.application.text.generatedAfterSave")}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formClientSecret">
        <Form.Label>{t("label.clientSecret")}</Form.Label>
        <Form.Control
          plaintext
          readOnly
          value={application?.clientSecret ? application.clientSecret : t("dev.application.text.generatedAfterSave")}
        />
      </Form.Group>
      <Form.Group className="mb-3 required" controlId="formGrantType">
        <Form.Label>{t("label.grantType")}</Form.Label>
        <ul>
          {application?.grantTypes.map((g, index) => (
            <li className="text-muted" key={index}>
              {g.title}
            </li>
          ))}
        </ul>
      </Form.Group>

      {authorizationCodeGrant && (
        <Form.Group className="mb-3" controlId="formRedirectUrls">
          <Form.Label>{t("label.redirectUris")}</Form.Label>
          <ul>
            {application?.redirectUris.map((r, index) => (
              <li className="text-muted" key={index}>
                {r}
              </li>
            ))}
          </ul>
        </Form.Group>
      )}

      <h3>{t("dev.services.label.services")}</h3>
      {services?.map((service, index) => (
        <Card key={index} className="my-4">
          <Card.Header>
            <div>
              <strong>{service.title}</strong>
            </div>
            <em>{"https://inbus.vsb.cz/service/" + service.contextPath + "/v" + service.apiVersion + "/..."}</em>
          </Card.Header>
          <Card.Body>
            <p>{service.description}</p>
            <ul>
              {service.scopes.map((scope, index) => (
                <li key={index}>
                  <Link to={"scopes/" + scope.id}>{scope.title}</Link>
                  {scope.readonly && <Eye className="mx-1 text-muted" title={t("title.scopeReadonly")} />}
                  {scope.respondsToUserIdentity && (
                    <PersonFill className="mx-1 text-muted" title={t("title.scopeRespondsToUserIdentity")} />
                  )}
                  {scope.requireUserIdentity && (
                    <PersonCheckFill className="mx-1 text-warning" title={t("title.scopeRequireUserIdentity")} />
                  )}
                  {" - "} {scope.description}
                </li>
              ))}
            </ul>
          </Card.Body>
        </Card>
      ))}
    </Container>
  );
}
