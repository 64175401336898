import { useEffect } from "react";
import { Form, Row, Col, Alert, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useLoginDeveloperMutation } from "../../../api/auth";
import * as yup from "yup";
import UI from "../../ui";

interface IFormInputs {
  username: string;
  password: string;
}

// schema validace
const schema = yup
  .object({
    username: yup.string().email().required(),
    password: yup.string().required(),
  })
  .required();

/**
 *	Prihlaseni
 */
export function Login() {
  // preklady
  const { t } = useTranslation();

  // navigace
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // validace formulare
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInputs>({
    defaultValues: {
      username: "",
      password: "",
    },
    resolver: yupResolver(schema),
  });

  // rest akce
  const [login, loginResult] = useLoginDeveloperMutation();

  useEffect(() => {
    document.title = t("app.pageTitle") + " - " + t("dev.login.header");
  });

  useEffect(() => {
    if (loginResult.isSuccess) {
      navigate(searchParams.get("rurl") || "/dev");
    }
  });

  const onSubmit = (data: IFormInputs) => {
    login({ body: data });
  };

  return (
    <Row className="justify-content-md-center">
      <Col xs="12" md="8" lg="6" xl="4" className="playground">
        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Card>
            <Card.Header>{t("dev.login.header")}</Card.Header>
            <Card.Body>
              {loginResult.isError && <Alert variant="danger">{t("dev.login.error.authentication")}</Alert>}
              <UI.Input
                control={control}
                name="username"
                label={t("dev.login.label.login")}
                type="email"
                placeholder={t("placeholder.email")}
              />
              <UI.Input
                control={control}
                name="password"
                label={t("dev.login.label.password")}
                type="password"
                placeholder={t("placeholder.password")}
              />
              <UI.ActionButton label={t("dev.login.button.login")} isLoading={loginResult.isLoading} />
            </Card.Body>
          </Card>
        </Form>
      </Col>
    </Row>
  );
}
