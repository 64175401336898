import { useEffect, useState } from "react";
import { Container, Tab, Tabs, Button, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import { useFindServiceQuery, useRemoveServiceMutation } from "../../../api/admin";
import Main from "../service/Main";
import Scopes from "../service/Scopes";
import Implementations from "../service/Implementations";
import { useAppDispatch } from "../../../app/hooks";
import { showInfo } from "../../message";
import { TrashFill } from "react-bootstrap-icons";

/**
 *	Uprava sluzby
 */
export function Service() {
  const [key, setKey] = useState("main");

  const dispatch = useAppDispatch();

  // preklady
  const { t } = useTranslation();

  const navigate = useNavigate();

  let { serviceId } = useParams();

  // akce odstraneni aplikace
  const [removeService, removeServiceResult] = useRemoveServiceMutation();

  let id = serviceId && serviceId !== "create" && removeServiceResult.isUninitialized ? parseInt(serviceId) : 0;

  let { data: service } = useFindServiceQuery({ serviceId: id }, { skip: id === 0 });

  // titulek stranky
  useEffect(() => {
    document.title = t("app.pageTitle") + " - " + t("admin.service.header");
  });

  // akce po ulozeni dat
  useEffect(() => {
    if (removeServiceResult.isSuccess) {
      dispatch(showInfo(t("info.removed")));
      navigate("/admin/services");
    }
  }, [dispatch, t, removeServiceResult, navigate]);

  const handleRemove = () => {
    if (window.confirm(t("confirm.remove")) && service?.id) {
      removeService({ serviceId: service.id });
    }
  };

  return (
    <Container fluid>
      <h1>
        {service?.id && (
          <Button
            className="float-end text-danger mt-3"
            variant="link"
            disabled={removeServiceResult.isLoading}
            onClick={handleRemove}
            title={t("admin.service.button.remove")}
          >
            {removeServiceResult.isLoading && <Spinner className="me-2" as="span" animation="border" size="sm" />}

            <TrashFill />
          </Button>
        )}
        {service?.title}
        <span className="header-type">{t("admin.service.header")}</span>
      </h1>
      {removeServiceResult.isUninitialized && (
        <Tabs activeKey={key} onSelect={(k) => (k != null ? setKey(k) : "")} className="mb-3">
          <Tab eventKey="main" title={t("admin.service.tab.main")}>
            <Main service={service} />
          </Tab>
          <Tab eventKey="scopes" title={t("admin.service.tab.scopes")} disabled={id === 0}>
            {service && <Scopes service={service} />}
          </Tab>
          <Tab eventKey="implementations" title={t("admin.service.tab.implementations")} disabled={id === 0}>
            {service && <Implementations service={service} />}
          </Tab>
        </Tabs>
      )}
    </Container>
  );
}
