import { useEffect } from "react";
import { Form, Button, Container, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams, useNavigate } from "react-router-dom";
import {
  useSaveDeveloperAccountMutation,
  useCreateDeveloperAccountMutation,
  useRemoveDeveloperAccountMutation,
  useFindDeveloperAccountQuery,
  DeveloperAccountBase,
} from "../../../api/admin";
import { useAppDispatch } from "../../../app/hooks";
import { showInfo } from "../../message";
import { stripEmpty } from "../../../utils/form";
import * as yup from "yup";
import UI from "../../ui";
import { TrashFill } from "react-bootstrap-icons";

/**
 *	Uprava aplikace
 */
export function Developer() {
  const dispatch = useAppDispatch();
  // preklady
  const { t } = useTranslation();

  const navigate = useNavigate();

  let { developerId } = useParams();

  // schema validace
  const schema: yup.SchemaOf<DeveloperAccountBase> = yup
    .object({
      id: yup.number(),
      version: yup.number(), // potreba pro validaci na serveru (stripUnknown: true)
      login: yup.string().email().trim().max(255).required(),
      firstName: yup.string().trim().max(255).required(),
      lastName: yup.string().trim().max(255).required(),
      organisation: yup.string().trim().max(255).optional().transform(stripEmpty),
      phone: yup.string().trim().max(255).optional().transform(stripEmpty),
      password:
        developerId === "create"
          ? yup.string().trim().required()
          : yup.string().trim().optional().transform(stripEmpty),
    })
    .required();

  // validace formulare
  const { control, handleSubmit, reset, setValue } = useForm<DeveloperAccountBase>({
    defaultValues: {
      login: "",
      firstName: "",
      lastName: "",
      organisation: "",
      phone: "",
      password: "",
    },
    resolver: yupResolver(schema, { stripUnknown: true }),
  });

  // akce odstraneni vyvojare
  const [removeDeveloperAccount, removeDeveloperAccountResult] = useRemoveDeveloperAccountMutation();

  let id =
    developerId && developerId !== "create" && removeDeveloperAccountResult.isUninitialized ? parseInt(developerId) : 0;

  let { data: developer } = useFindDeveloperAccountQuery({ developerAccountId: id }, { skip: id === 0 });

  // akce vytvoreni vyvojare
  const [createDeveloperAccount, createDeveloperAccountResult] = useCreateDeveloperAccountMutation();

  // akce ulozeni vyvojare
  const [saveDeveloperAccount, saveDeveloperAccountResult] = useSaveDeveloperAccountMutation();

  // nacteni hodnot do formulare
  useEffect(() => {
    if (developer) {
      // konverze na parametr formRedirectUris, protoze react-hook-form neumi zpracovat flat pole
      reset(developer);
    }
  }, [reset, developer]);

  // akce po vytvoreni dat
  useEffect(() => {
    if (createDeveloperAccountResult.isSuccess) {
      dispatch(showInfo(t("info.saved")));
      navigate("/admin/developers/" + createDeveloperAccountResult.data.id, { replace: true });
    }
  }, [dispatch, t, createDeveloperAccountResult, navigate]);

  // akce po ulozeni dat
  useEffect(() => {
    if (saveDeveloperAccountResult.isSuccess) {
      dispatch(showInfo(t("info.saved")));
    }
  }, [dispatch, t, saveDeveloperAccountResult]);

  // akce po ulozeni dat
  useEffect(() => {
    if (removeDeveloperAccountResult.isSuccess) {
      dispatch(showInfo(t("info.removed")));
      navigate("/admin/developers");
    }
  }, [dispatch, t, removeDeveloperAccountResult, navigate]);

  // ulozeni formulare
  const onSubmit = (data: DeveloperAccountBase) => {
    if (data.id && developer?.id) {
      saveDeveloperAccount({ developerAccountId: developer.id, developerAccountBase: data });
    } else {
      createDeveloperAccount({ developerAccountBase: data });
    }
  };

  const handleRemove = () => {
    if (window.confirm(t("confirm.remove")) && developer?.id) {
      removeDeveloperAccount({ developerAccountId: developer.id });
    }
  };

  const generatePassword = () => {
    setValue("password", Math.random().toString(20).substring(2, 14));
  };

  return (
    <Container fluid>
      <h1>
        {developer?.id && (
          <div>
            <Button
              className="float-end text-danger mt-2"
              variant="link"
              disabled={removeDeveloperAccountResult.isLoading}
              title={t("admin.developer.button.remove")}
              onClick={handleRemove}
            >
              {removeDeveloperAccountResult.isLoading && (
                <Spinner className="me-2" as="span" animation="border" size="sm" />
              )}
              <TrashFill />
            </Button>
          </div>
        )}
        {t("admin.developer.header")}
      </h1>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <UI.Input control={control} name="login" label={t("label.login")} required={true} />
        <UI.Input control={control} name="firstName" label={t("label.firstName")} required={true} />
        <UI.Input control={control} name="lastName" label={t("label.lastName")} required={true} />
        <UI.Input control={control} name="organisation" label={t("label.organisation")} />
        <UI.Input control={control} name="phone" label={t("label.phone")} />
        <UI.Input
          control={control}
          name="password"
          label={t("label.password")}
          placeholder={t("admin.developer.placeholder.unchanged")}
        >
          <Button variant="outline-secondary" onClick={generatePassword}>
            {t("admin.developer.button.generate")}
          </Button>
        </UI.Input>
        {!developer?.id && (
          <UI.ActionButton
            type="submit"
            isLoading={createDeveloperAccountResult.isLoading}
            label={t("button.create")}
          />
        )}
        {developer?.id && (
          <UI.ActionButton type="submit" isLoading={saveDeveloperAccountResult.isLoading} label={t("button.save")} />
        )}
      </Form>
    </Container>
  );
}
