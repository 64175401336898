import { useEffect } from "react";
import { Container, Table, Form, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { PencilSquare, PlusSquare, FlagFill } from "react-bootstrap-icons";
import { useFindApplicationsQuery } from "../../../api/admin";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";

// pro pouziti ve formulari
type CriteriaForm = {
  search?: string;
};

// schema validace
const schema: yup.SchemaOf<CriteriaForm> = yup.object({
  search: yup.string().optional(),
});

/**
 *	Aplikace
 */
export function Applications() {
  // preklady
  const { t } = useTranslation();

  // validace formulare
  const {
    control,
    watch,
    formState: { errors, isValid },
  } = useForm<CriteriaForm>({
    mode: "onChange",
    defaultValues: {
      search: "",
    },
    resolver: yupResolver(schema, { stripUnknown: true }),
  });

  const search = isValid ? watch("search") : undefined;

  const { data: applications } = useFindApplicationsQuery({ search: search });

  useEffect(() => {
    document.title = t("app.pageTitle") + " - " + t("admin.applications.header");
  });

  return (
    <Container fluid>
      <h1>{t("admin.applications.header")}</h1>
      <Row sm={2} md={3}>
        <Col>
          <Form noValidate>
            <Form.Group className="mb-3" controlId="formSearch">
              <Controller
                name="search"
                control={control}
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    isInvalid={errors.search != null}
                    placeholder={t("admin.applications.placeholder.search")}
                  />
                )}
              />
              <Form.Control.Feedback type="invalid">{errors.search?.message}</Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Col>
      </Row>
      <Table hover responsive="md">
        <thead>
          <tr>
            <th>{t("admin.applications.column.application")}</th>
            <th>{t("admin.applications.column.developer")}</th>
            <th className="text-end">
              <Link to={"/admin/applications/create"}>
                <PlusSquare />
              </Link>
            </th>
          </tr>
        </thead>
        <tbody>
          {applications?.map((a, index) => (
            <tr key={index}>
              <td>{a.title}</td>
              <td>
                {a.developers.map((d, index) => (
                  <div key={index}>
                    {d.firstName} {d.lastName} <span className="text-muted">({d.login})</span>{" "}
                    {d.admin && <FlagFill title={t("admin.applications.title.admin")} />}
                  </div>
                ))}
              </td>
              <td className="text-end">
                <Link to={"/admin/applications/" + a.id}>
                  <PencilSquare />
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}
