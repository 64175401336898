import { inbusApi, UploadMyApplicationLogoApiArg } from "./devBase";

/**
 * Rozsireni / Zmena vygenerovaneho API
 */
const injectedRtkApi = inbusApi.enhanceEndpoints({
  endpoints: {
    uploadMyApplicationLogo: {
      query(queryArg: UploadMyApplicationLogoApiArg) {
        let formData = new FormData();
        formData.append("logo", queryArg.logo);
        return {
          url: `/dev/applications/${queryArg.clientAppId}/logo`,
          method: "POST",
          body: formData,
        };
      },
    },
  },
});

export * from "./devBase";
export const { useUploadMyApplicationLogoMutation } = injectedRtkApi;
