import { inbusSplitApi as api } from "./inbusApi";
export const addTagTypes = ["UserProfile"] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getUserProfile: build.query<GetUserProfileApiResponse, GetUserProfileApiArg>({
        query: () => ({ url: `/auth` }),
        providesTags: ["UserProfile"],
      }),
      loginDeveloper: build.mutation<LoginDeveloperApiResponse, LoginDeveloperApiArg>({
        query: (queryArg) => ({ url: `/auth/loginDeveloper`, method: "POST", body: queryArg.body }),
        invalidatesTags: ["UserProfile"],
      }),
      logout: build.mutation<LogoutApiResponse, LogoutApiArg>({
        query: () => ({ url: `/auth/logout`, method: "POST" }),
        invalidatesTags: ["UserProfile"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as inbusApi };
export type GetUserProfileApiResponse = /** status 200 undefined */
  | UserProfile
  | /** status 204 Unauthenticated */ undefined;
export type GetUserProfileApiArg = void;
export type LoginDeveloperApiResponse = unknown;
export type LoginDeveloperApiArg = {
  body: {
    username: string;
    password: string;
  };
};
export type LogoutApiResponse = unknown;
export type LogoutApiArg = void;
export type UserProfile = {
  login: string;
  organisation?: string;
  organisationTitle?: string;
  roles: string[];
};
export const { useGetUserProfileQuery, useLoginDeveloperMutation, useLogoutMutation } = injectedRtkApi;
