import { useEffect } from "react";
import { Form, Button, Spinner, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFindMyAccountQuery, useSaveMyAccountMutation, DeveloperAccount } from "../../../api/dev";
import { useAppDispatch } from "../../../app/hooks";
import { showInfo } from "../../message";
import { Link } from "react-router-dom";
import { stripEmpty } from "../../../utils/form";
import * as yup from "yup";
import UI from "../../ui";

// schema validace
const schema: yup.SchemaOf<DeveloperAccount> = yup
  .object({
    id: yup.number(),
    login: yup.string().required(),
    version: yup.number().required(),
    firstName: yup.string().trim().required(),
    lastName: yup.string().trim().required(),
    organisation: yup.string().optional().transform(stripEmpty),
    phone: yup.string().optional().transform(stripEmpty),
  })
  .required();

/**
 *	Editace profilu vyvojare
 */
export function Profile() {
  const dispatch = useAppDispatch();

  // preklady
  const { t } = useTranslation();

  // validace formulare
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<DeveloperAccount>({
    defaultValues: {
      firstName: "",
      lastName: "",
      organisation: "",
      phone: "",
    },
    resolver: yupResolver(schema, { stripUnknown: true }),
  });

  // nacteni profilu
  const { data: account } = useFindMyAccountQuery();

  // akce ulozeni profilu
  const [saveAccount, saveAccountResult] = useSaveMyAccountMutation();

  // titulek stranky
  useEffect(() => {
    document.title = t("app.pageTitle") + " - " + t("dev.profile.header");
  });

  // nacteni hodnot do formulare
  useEffect(() => {
    if (account) {
      reset(account);
    }
  }, [reset, account]);

  // akce po ulozeni dat
  useEffect(() => {
    if (saveAccountResult.isSuccess) {
      dispatch(showInfo(t("info.saved")));
    }
  }, [dispatch, t, saveAccountResult]);

  // ulozeni formulare
  const onSubmit = (data: DeveloperAccount) => {
    saveAccount({ developerAccount: data });
  };

  return (
    <div>
      <h1>{t("dev.profile.header")}</h1>
      <Row className="justify-content-md-center">
        <Col xs="12" md="10" lg="8">
          <Form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3 required" controlId="formLogin">
              <Form.Label>{t("dev.profile.label.login")}</Form.Label>
              <Form.Control plaintext readOnly defaultValue={account?.login} />
            </Form.Group>
            <UI.Input control={control} name="firstName" label={t("dev.profile.label.firstName")} required={true} />
            <UI.Input control={control} name="lastName" label={t("dev.profile.label.lastName")} required={true} />
            <UI.Input control={control} name="organisation" label={t("dev.profile.label.organisation")} />
            <UI.Input control={control} name="phone" label={t("dev.profile.label.phone")} />
            <Form.Group className="mb-3" controlId="formPassword">
              <Form.Label>{t("dev.profile.label.password")}</Form.Label>
              <div className="form-control-plaintext">
                <Link to="/dev/profile/password">{t("dev.profile.text.changePassword")}</Link>
              </div>
            </Form.Group>
            <UI.ActionButton label={t("button.save")} isLoading={saveAccountResult.isLoading} />
          </Form>
        </Col>
      </Row>
    </div>
  );
}
