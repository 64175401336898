import { useEffect } from "react";
import { Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";

/**
 *	Pristup zamitnut
 */
export function AccessDenied() {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("app.pageTitle") + " - " + t("access.header");
  });

  return (
    <div className="text-center m-5">
      <Alert variant="warning">
        <Alert.Heading>{t("access.header")}</Alert.Heading>
        {t("access.text.denied")}
      </Alert>
    </div>
  );
}
