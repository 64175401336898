import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useFindServiceScopeClientAppsQuery } from "../../../api/admin";
import { Link } from "react-router-dom";

interface Props {
  serviceId: number;
  scopeId: number;
  show: boolean;
  onHide: () => void;
}
/**
 *	Aplikace vyuzivajici rozsah sluzby
 */
export default function ClientAppsModal({ serviceId, scopeId, show, onHide }: Props) {
  // preklady
  const { t } = useTranslation();

  let { data: clientApps } = useFindServiceScopeClientAppsQuery({ serviceId: serviceId, scopeId: scopeId });

  return (
    <Modal show={show} onHide={onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{t("admin.service.modal.clientAppsHeader")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ol>
          {clientApps &&
            clientApps.map((c, index) => (
              <li key={index}>
                <Link to={"/admin/applications/" + c.id}>{c.title}</Link>
                <em> ({c.grantTypes.map((g) => g.key).join(", ")})</em>
                <p>{c.description}</p>
              </li>
            ))}
        </ol>
      </Modal.Body>
    </Modal>
  );
}
