import { useEffect } from "react";
import { Container, Form, Row, Col, Button, InputGroup, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useGetUserProfileQuery } from "../../../api/auth";
import {
  useFindMyApplicationQuery,
  useAddMyApplicationDeveloperMutation,
  useRemoveMyApplicationDeveloperMutation,
  useSaveMyApplicationDeveloperMutation,
} from "../../../api/dev";
import { useAppDispatch } from "../../../app/hooks";
import { showInfo } from "../../message";
import { XSquare, Plus } from "react-bootstrap-icons";
import * as yup from "yup";

// pro pouziti ve formulari
type DeveloperForm = {
  email: string;
};

// schema validace
const schema: yup.SchemaOf<DeveloperForm> = yup.object({
  email: yup.string().email().required(),
});

/**
 *	Uprava aplikace
 */
export function Permissions() {
  const dispatch = useAppDispatch();
  // preklady
  const { t } = useTranslation();

  // validace formulare
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<DeveloperForm>({
    defaultValues: {
      email: "",
    },
    resolver: yupResolver(schema, { stripUnknown: true }),
  });

  let { clientAppId } = useParams();

  let id = clientAppId ? parseInt(clientAppId) : 0;

  // nacteni profilu
  const { data: userProfile } = useGetUserProfileQuery();

  const { data: application } = useFindMyApplicationQuery({ clientAppId: id }, { skip: id === 0 });

  // akce
  const [addDeveloper, addDeveloperResult] = useAddMyApplicationDeveloperMutation();
  const [removeDeveloper, removeDeveloperResult] = useRemoveMyApplicationDeveloperMutation();
  const [saveDeveloper, saveDeveloperResult] = useSaveMyApplicationDeveloperMutation();

  // titulek stranky
  useEffect(() => {
    document.title = t("app.pageTitle") + " - " + t("dev.permissions.header");
  });

  const handleRemove = (developerId: number | undefined) => {
    if (window.confirm(t("confirm.remove")) && application?.id && developerId) {
      removeDeveloper({ clientAppId: application.id, developerId: developerId });
    }
  };

  // akce po ulozeni dat
  useEffect(() => {
    if (removeDeveloperResult.isSuccess) {
      dispatch(showInfo(t("info.removed")));
    }
  }, [dispatch, t, removeDeveloperResult]);

  // ulozeni formulare
  const onSubmit = (data: DeveloperForm) => {
    //    console.log("data = ", data);
    if (application?.id) {
      addDeveloper({ clientAppId: application.id, developerBase: { admin: false, developerAccountEmail: data.email } });
    }
  };

  // akce po ulozeni dat
  useEffect(() => {
    if (addDeveloperResult.isSuccess) {
      dispatch(showInfo(t("info.saved")));
      reset();
    }
  }, [dispatch, t, addDeveloperResult, reset]);

  // zjisteni zda uzivatel ma prava admin aplikace
  let admin = false;
  application?.developers.forEach((d) => {
    if (d.login === userProfile?.login && d.admin) admin = true;
  });

  const handleAdmin = (developerId: number | undefined, devAdmin: boolean) => {
    if (admin && application?.id && developerId) {
      saveDeveloper({ clientAppId: application.id, developerId: developerId, developerBase: { admin: devAdmin } });
    }
  };

  // akce po ulozeni dat
  useEffect(() => {
    if (saveDeveloperResult.isSuccess) {
      dispatch(showInfo(t("info.saved")));
    }
  }, [dispatch, t, saveDeveloperResult]);

  return (
    <Container fluid>
      <h1>
        {application?.title} <span className="header-type">{t("dev.permissions.header")}</span>
      </h1>
      <Table hover responsive="md">
        <thead>
          <tr>
            <th>{t("column.firstName")}</th>
            <th>{t("column.lastName")}</th>
            <th>{t("column.email")}</th>
            <th>{t("column.organisation")}</th>
            <th>{t("column.admin")}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {application?.developers.map((d, index) => (
            <tr key={index}>
              <td>{d.firstName}</td>
              <td>{d.lastName}</td>
              <td>{d.login}</td>
              <td>{d.organisation}</td>
              <td>
                <Form.Check
                  type="checkbox"
                  checked={d.admin}
                  onChange={() => handleAdmin(d.id, !d.admin)}
                  readOnly={!admin}
                />
              </td>
              <td>
                {admin && (
                  <Button className="py-0 text-danger" variant="link" onClick={() => handleRemove(d.id)}>
                    <XSquare />
                  </Button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {admin && (
        <Row md={3}>
          <Col>
            <Form noValidate onSubmit={handleSubmit(onSubmit)}>
              <Form.Group className="mb-3" controlId="formWeb">
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <InputGroup className="mb-3" hasValidation={true}>
                      <Form.Control
                        {...field}
                        isInvalid={errors.email != null}
                        placeholder={t("dev.permissions.placeholder.email")}
                      />
                      <Button variant="outline-primary" type="submit">
                        <Plus />
                        {t("dev.permissions.button.add")}
                      </Button>
                      <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
                    </InputGroup>
                  )}
                />
              </Form.Group>
            </Form>
          </Col>
        </Row>
      )}
    </Container>
  );
}
