import { useEffect, useRef } from "react";
import { Form, Button, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import {
  useSaveApplicationMutation,
  useCreateApplicationMutation,
  useUploadApplicationLogoMutation,
  ClientAppBase,
  ClientApp,
} from "../../../api/admin";
import { useAppDispatch } from "../../../app/hooks";
import { showInfo, showError } from "../../message";
import { stripEmpty } from "../../../utils/form";
import * as yup from "yup";
import UI from "../../ui";

// schema validace
const schema: yup.SchemaOf<ClientAppBase> = yup
  .object({
    id: yup.number(),
    title: yup.string().trim().max(255).required(),
    titleEN: yup.string().trim().max(255),
    version: yup.number(), // potreba pro validaci na serveru (stripUnknown: true)
    description: yup.string().trim().max(4095).required(),
    descriptionEN: yup.string().trim().max(4095),
    logo: yup.mixed(),
    web: yup.string().url().optional().transform(stripEmpty),
    redirectUris: yup.array(),
    clientId: yup.string(),
    clientSecret: yup.string(),
    scopeIds: yup.array(),
    grantTypeIds: yup.array(),
  })
  .required();

interface Props {
  application?: ClientApp;
}

/**
 *	Uprava aplikace
 */
export default function Main({ application }: Props) {
  const dispatch = useAppDispatch();
  // preklady
  const { t } = useTranslation();

  const navigate = useNavigate();

  // validace formulare
  const { control, handleSubmit, reset } = useForm<ClientAppBase>({
    defaultValues: {
      title: "",
      description: "",
      web: "",
      redirectUris: [],
    },
    resolver: yupResolver(schema, { stripUnknown: true }),
  });

  // akce vytvoreni profilu
  const [createApplication, createApplicationResult] = useCreateApplicationMutation();

  // akce ulozeni aplikace
  const [saveApplication, saveApplicationResult] = useSaveApplicationMutation();

  // akce nahrani loga
  const [uploadLogo, uploadLogoResult] = useUploadApplicationLogoMutation();

  const inputRef = useRef<HTMLInputElement>();

  // nacteni hodnot do formulare
  useEffect(() => {
    if (application) {
      // konverze na parametr formRedirectUris, protoze react-hook-form neumi zpracovat flat pole
      reset(application);
    }
  }, [reset, application]);

  // akce po vytvoreni dat
  useEffect(() => {
    if (createApplicationResult.isSuccess) {
      dispatch(showInfo(t("info.saved")));
      navigate("/admin/applications/" + createApplicationResult.data.id, { replace: true });
    }
  }, [dispatch, t, createApplicationResult, navigate]);

  // akce po ulozeni dat
  useEffect(() => {
    if (saveApplicationResult.isSuccess) {
      dispatch(showInfo(t("info.saved")));
    }
  }, [dispatch, t, saveApplicationResult]);

  // akce po ulozeni dat
  useEffect(() => {
    if (uploadLogoResult.isSuccess) {
      dispatch(showInfo(t("admin.application.info.logoUploaded")));
    }
  }, [dispatch, t, uploadLogoResult]);

  // ulozeni formulare
  const onSubmit = (data: ClientAppBase) => {
    if (data.id && application?.id) {
      saveApplication({ clientAppId: application.id, clientAppBase: data });
    } else {
      createApplication({ clientAppBase: data });
    }
  };

  const handleUpload = () => {
    inputRef.current?.click();
  };

  const handleUploadFile = () => {
    let files = inputRef.current?.files;
    if (files && application?.id) {
      let file = files[0];
      if ("image/png" !== file.type) {
        dispatch(showError(t("error.uploadPngRequired")));
      } else if (file.size > 102400) {
        dispatch(showError(t("error.uploadPngFileSize", { value: 102400 })));
      } else {
        uploadLogo({ clientAppId: application.id, logo: file });
      }
    }
  };

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      <UI.Input control={control} name="title" label={t("label.title")} required={true} />
      <UI.Input control={control} name="description" label={t("label.description")} rows={3} required={true} />
      <UI.Input control={control} name="descriptionEN" label={t("label.description") + " (EN)"} rows={3} />
      <UI.Input control={control} name="web" label={t("label.web")} />
      <Form.Group className="mb-3" controlId="formLogo">
        <Form.Label>{t("label.logo")}</Form.Label>
        <div>
          <input
            ref={(element) => {
              if (element) inputRef.current = element;
            }}
            className="d-none"
            type="file"
            onChange={() => handleUploadFile()}
          />

          {application?.logo && (
            <Image
              style={{ maxHeight: 74 }}
              src={"data:image/png;base64," + (application?.logo as unknown as string)}
              alt="logo"
              thumbnail={true}
            />
          )}
          {!application?.logo && <Image src="/noimage.png" alt="logo" thumbnail={true} />}
          <Button
            variant="light"
            onClick={() => {
              handleUpload();
            }}
          >
            {t("button.logo")}
          </Button>
        </div>
      </Form.Group>
      {!application?.id && <UI.ActionButton label={t("button.create")} isLoading={createApplicationResult.isLoading} />}
      {application?.id && <UI.ActionButton label={t("button.save")} isLoading={saveApplicationResult.isLoading} />}
    </Form>
  );
}
