import { useEffect, useState } from "react";
import { Container, Tab, Tabs, Button, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import { useFindApplicationQuery, useRemoveApplicationMutation } from "../../../api/admin";
import Main from "../application/Main";
import Permissions from "../application/Permissions";
import Services from "../application/Services";
import { useAppDispatch } from "../../../app/hooks";
import { showInfo } from "../../message";
import { TrashFill } from "react-bootstrap-icons";

/**
 *	Uprava aplikace
 */
export function Application() {
  const [key, setKey] = useState("main");

  const dispatch = useAppDispatch();

  // preklady
  const { t } = useTranslation();

  const navigate = useNavigate();

  let { clientAppId } = useParams();

  // akce odstraneni aplikace
  const [removeApplication, removeApplicationResult] = useRemoveApplicationMutation();

  let id =
    clientAppId && clientAppId !== "create" && removeApplicationResult.isUninitialized ? parseInt(clientAppId) : 0;

  let { data: application } = useFindApplicationQuery({ clientAppId: id }, { skip: id === 0 });

  // titulek stranky
  useEffect(() => {
    document.title = t("app.pageTitle") + " - " + t("admin.application.header");
  });

  // akce po ulozeni dat
  useEffect(() => {
    if (removeApplicationResult.isSuccess) {
      dispatch(showInfo(t("info.removed")));
      navigate("/admin/applications");
    }
  }, [dispatch, t, removeApplicationResult, navigate]);

  const handleRemove = () => {
    if (window.confirm(t("confirm.remove")) && application?.id) {
      removeApplication({ clientAppId: application.id });
    }
  };

  return (
    <Container fluid>
      <h1>
        {application?.id && (
          <Button
            className="float-end text-danger mt-3"
            variant="link"
            disabled={removeApplicationResult.isLoading}
            onClick={handleRemove}
            title={t("admin.application.button.remove")}
          >
            {removeApplicationResult.isLoading && <Spinner className="me-2" as="span" animation="border" size="sm" />}
            <TrashFill />
          </Button>
        )}
        {application?.title}
        <span className="header-type">{t("admin.application.header")}</span>
      </h1>
      {removeApplicationResult.isUninitialized && (
        <Tabs activeKey={key} onSelect={(k) => (k != null ? setKey(k) : "")} className="mb-3">
          <Tab eventKey="main" title={t("admin.application.tab.main")}>
            <Main application={application} />
          </Tab>
          <Tab eventKey="permissions" title={t("admin.application.tab.permissions")} disabled={id === 0}>
            {application && <Permissions application={application} />}
          </Tab>
          <Tab eventKey="services" title={t("admin.application.tab.services")} disabled={id === 0}>
            {application && <Services application={application} />}
          </Tab>
        </Tabs>
      )}
    </Container>
  );
}
